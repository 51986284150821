import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';

import Field from 'shared/Field';
import Select from 'shared/Select';

import { YES_NO } from 'constants/Eticket';

const Billing = ({ errors, formData, onInputChange, quote }) => {
  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Field label='Payment Mode' style={{ width: 'calc(50% - 5px)' }}>
          <Select
            invalid={errors.includes('billing_method')}
            onChange={onInputChange.bind(null, 'billing_method', null)}
            options={[
              { value: '', label: '-- Select One --' },
              { value: 'direct_billing', label: 'Direct Billing' },
              { value: 'eft', label: 'EFT' }
            ]}
            required={true}
            value={formData.billing_method}
          />
        </Field>
        <Field label='Payment Frequency' style={{ width: 'calc(50% - 5px)' }}>
          <Select
            invalid={errors.includes('billing_frequency')}
            onChange={onInputChange.bind(null, 'billing_frequency', null)}
            options={[
              { value: '', label: '-- Select One --' },
              { value: 'monthly', label: `Monthly (${Numeral(quote.premium_monthly).format('$0.00')})` },
              { value: 'quarterly', label: `Quarterly (${Numeral(quote.premium_quarterly).format('$0.00')})` },
              { value: 'semi_annual', label: `Semi-Annual (${Numeral(quote.premium_semi_annual).format('$0.00')})` },
              { value: 'annual', label: `Annual (${Numeral(quote.premium_annual).format('$0.00')})` }
            ]}
            required={true}
            value={formData.billing_frequency}
          />
        </Field>
      </div>

      <Field label='Automatic Premium Loan'>
        <Select onChange={onInputChange.bind(null, 'automatic_premium_loan', null)} options={[{ value: '', label: '-- Select One --' }].concat(YES_NO)} value={formData.automatic_premium_loan} />
      </Field>
      <Field label='Dividend Option'>
        <Select
          onChange={onInputChange.bind(null, 'dividend_option', null)}
          options={[
            { value: '', label: '-- Select One --' },
            { value: 'accumulation', label: 'Accumulation' },
            { value: 'cash', label: 'Cash' },
            { value: 'paid_up_additions', label: 'Paid Up Additions' },
            { value: 'pay_premium', label: 'Pay Premium' }
          ]}
          value={formData.dividend_option}
        />
      </Field>
    </React.Fragment>
  );
};

Billing.propTypes = {
  errors: PropTypes.array,
  formData: PropTypes.object,
  onInputChange: PropTypes.func,
  quote: PropTypes.object
};

export default Billing;
