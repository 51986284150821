import React from 'react';
import PropTypes from 'prop-types';

import { FontSizes, Colors } from 'constants/Clementine';

const Progress = ({ currentStep, style, title, totalSteps }) => {
  const styles = {
    track: {
      position: 'relative',
      height: 4,
      borderRadius: 4,
      overflow: 'hidden',
      background: '#e9e9e9'
    },
    progress: {
      backgroundColor: Colors.GREEN.hex,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0
    },

    details: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '30px 30px 0px'
    },
    title: {
      fontSize: FontSizes.XLARGE,
      color: Colors.GRAY.hex
    },
    step: {
      textAlign: 'right',
      marginBottom: 4,
      fontSize: FontSizes.SMALL,
      color: '#999'
    }
  };

  return (
    <div style={style}>
      <div style={styles.details}>
        <div style={styles.title}>{title}</div>
        <div style={{ width: '50%', marginBottom: 6 }}>
          <div style={styles.step}>
            Step {currentStep}/{totalSteps}
          </div>
          <div style={styles.track}>
            <div style={Object.assign({}, styles.progress, { width: `${(currentStep / totalSteps) * 100}%` })} />
          </div>
        </div>
      </div>
    </div>
  );
};

Progress.propTypes = {
  currentStep: PropTypes.number,
  title: PropTypes.node,
  totalSteps: PropTypes.number
};

export default Progress;
