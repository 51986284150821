module.exports = {
  DATE_REGEX: /[1-2][0,9][0-9][0-9]-[0,1][0-9]-[0-3][0-9]/,
  RELATIONSHIPS_CONDENSED: [
    { value: 'business', label: 'Business' },
    { value: 'child', label: 'Child' },
    { value: 'creditors', label: 'Creditors' },
    { value: 'ex_spouse', label: 'Ex-Spouse' },
    { value: 'grand_child', label: 'Grand Child' },
    { value: 'no_relationship', label: 'No Relationship' },
    { value: 'other', label: 'Other' },
    { value: 'parent', label: 'Parent' },
    { value: 'sibling', label: 'Sibling' },
    { value: 'spouse', label: 'Spouse' },
    { value: 'step_child', label: 'Step Child' },
    { value: 'step_parent', label: 'Step Parent' },
    { value: 'trust', label: 'Trust' }
  ],
  RELATIONSHIPS: [
    { value: 'none', label: 'No Relationship' },
    { value: 'aunt', label: 'Aunt' },
    { value: 'brother', label: 'Brother' },
    { value: 'brother_in_law', label: 'Brother-in-law' },
    { value: 'custodian', label: 'Custodian' },
    { value: 'daughter', label: 'Daughter' },
    { value: 'daughter_in_law', label: 'Daughter-in-law' },
    { value: 'ex_husband', label: 'Ex-husband' },
    { value: 'ex_wife', label: 'Ex-wife' },
    { value: 'father', label: 'Father' },
    { value: 'father_in_law', label: 'Father-in-law' },
    { value: 'fiance', label: 'Fiancee' },
    { value: 'granddaughter', label: 'Granddaughter' },
    { value: 'grandfather', label: 'Grandfather' },
    { value: 'grandson', label: 'Grandson' },
    { value: 'husband', label: 'Husband' },
    { value: 'mother', label: 'Mother' },
    { value: 'mother_in_law', label: 'Mother-in-law' },
    { value: 'nephew', label: 'Nephew' },
    { value: 'niece', label: 'Niece' },
    { value: 'sister', label: 'Sister' },
    { value: 'sister_in_law', label: 'Sister-in-law' },
    { value: 'son', label: 'Son' },
    { value: 'son_in_law', label: 'Son-in-law' },
    { value: 'step_daughter', label: 'Step Daughter' },
    { value: 'step_father', label: 'Step Father' },
    { value: 'step_mother', label: 'Step Mother' },
    { value: 'step_son', label: 'Step Son' },
    { value: 'uncle', label: 'Uncle' },
    { value: 'unknown', label: 'Unknown' },
    { value: 'wife', label: 'Wife' },
    { value: 'other', label: 'Other' }
  ],
  MARITAL_STATUSES: [
    { value: 'married', label: 'Married' },
    { value: 'single', label: 'Single' },
    { value: 'widowed', label: 'Widowed' },
    { value: 'divorced', label: 'Divorced' },
    { value: 'separated', label: 'Separated' },
    { value: 'domestic_partner', label: 'Domestic Partner' }
  ],
  GENDERS: [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
  ],
  YES_NO: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ],
  STATES: [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'FM', label: 'Federated States of Micronesia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MH', label: 'Marshal Islands' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PW', label: 'Palau' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
  ],
  COUNTRIES: [
    { value: 'United States', label: 'United States' },
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Aland Islands', label: 'Aland Islands' },
    { value: 'Albania', label: 'Albania' },
    { value: 'Algeria', label: 'Algeria' },
    { value: 'American Samoa', label: 'American Samoa' },
    { value: 'Andorra', label: 'Andorra' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Anguilla', label: 'Anguilla' },
    { value: 'Antarctica', label: 'Antarctica' },
    { value: 'Antigua And Barbuda', label: 'Antigua And Barbuda' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Aruba', label: 'Aruba' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Azerbaijan', label: 'Azerbaijan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbados', label: 'Barbados' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bermuda', label: 'Bermuda' },
    { value: 'Bhutan', label: 'Bhutan' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Bosnia and Herzegowina', label: 'Bosnia and Herzegowina' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Bouvet Island', label: 'Bouvet Island' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
    { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cambodia', label: 'Cambodia' },
    { value: 'Cameroon', label: 'Cameroon' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Cape Verde', label: 'Cape Verde' },
    { value: 'Cayman Islands', label: 'Cayman Islands' },
    { value: 'Central African Republic', label: 'Central African Republic' },
    { value: 'Chad', label: 'Chad' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Christmas Island', label: 'Christmas Island' },
    { value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Comoros', label: 'Comoros' },
    { value: 'Congo', label: 'Congo' },
    { value: 'Congo, Democratic Republic of the', label: 'Congo, Democratic Republic of the' },
    { value: 'Cook Islands', label: 'Cook Islands' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: "Cote d'Ivoire", label: "Cote d'Ivoire" },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Cyprus', label: 'Cyprus' },
    { value: 'Czech Republic', label: 'Czech Republic' },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Djibouti', label: 'Djibouti' },
    { value: 'Dominica', label: 'Dominica' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { value: 'Eritrea', label: 'Eritrea' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Ethiopia', label: 'Ethiopia' },
    { value: 'Falkland Islands (Malvinas)', label: 'Falkland Islands (Malvinas)' },
    { value: 'Faroe Islands', label: 'Faroe Islands' },
    { value: 'Fiji', label: 'Fiji' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France' },
    { value: 'French Guiana', label: 'French Guiana' },
    { value: 'French Polynesia', label: 'French Polynesia' },
    { value: 'French Southern Territories', label: 'French Southern Territories' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Gambia', label: 'Gambia' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Gibraltar', label: 'Gibraltar' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Greenland', label: 'Greenland' },
    { value: 'Grenada', label: 'Grenada' },
    { value: 'Guadeloupe', label: 'Guadeloupe' },
    { value: 'Guam', label: 'Guam' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guernsey', label: 'Guernsey' },
    { value: 'Guinea', label: 'Guinea' },
    { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
    { value: 'Guyana', label: 'Guyana' },
    { value: 'Haiti', label: 'Haiti' },
    { value: 'Heard and McDonald Islands', label: 'Heard and McDonald Islands' },
    { value: 'Holy See (Vatican City State)', label: 'Holy See (Vatican City State)' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hong Kong', label: 'Hong Kong' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'India', label: 'India' },
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Iran, Islamic Republic of', label: 'Iran, Islamic Republic of' },
    { value: 'Iraq', label: 'Iraq' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Isle of Man', label: 'Isle of Man' },
    { value: 'Israel', label: 'Israel' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Jersey', label: 'Jersey' },
    { value: 'Jordan', label: 'Jordan' },
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kiribati', label: 'Kiribati' },
    { value: "Korea, Democratic People's Republic of (North)", label: "Korea, Democratic People's Republic of (North)" },
    { value: 'Korea, Republic of (South)', label: 'Korea, Republic of (South)' },
    { value: 'Kuwait', label: 'Kuwait' },
    { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
    { value: "Lao People's Democratic Republic", label: "Lao People's Democratic Republic" },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Liberia', label: 'Liberia' },
    { value: 'Libyan Arab Jamahiriya', label: 'Libyan Arab Jamahiriya' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Macao (Macau)', label: 'Macao (Macau)' },
    { value: 'Macedonia, The Former Yugoslav Republic Of', label: 'Macedonia, The Former Yugoslav Republic Of' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malawi', label: 'Malawi' },
    { value: 'Malaysia', label: 'Malaysia' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Martinique', label: 'Martinique' },
    { value: 'Mauritania', label: 'Mauritania' },
    { value: 'Mauritius', label: 'Mauritius' },
    { value: 'Mayotte', label: 'Mayotte' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Micronesia, Federated States of', label: 'Micronesia, Federated States of' },
    { value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolia', label: 'Mongolia' },
    { value: 'Montenegro', label: 'Montenegro' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Morocco', label: 'Morocco' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Myanmar', label: 'Myanmar' },
    { value: 'Namibia', label: 'Namibia' },
    { value: 'Nauru', label: 'Nauru' },
    { value: 'Nepal', label: 'Nepal' },
    { value: 'Netherlands (Holland)', label: 'Netherlands (Holland)' },
    { value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
    { value: 'New Caledonia', label: 'New Caledonia' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'Niue', label: 'Niue' },
    { value: 'Norfolk Island', label: 'Norfolk Island' },
    { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Palestinian Territory, Occupied', label: 'Palestinian Territory, Occupied' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Papua New Guinea', label: 'Papua New Guinea' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Pitcairn', label: 'Pitcairn' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Puerto Rico', label: 'Puerto Rico' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Reunion', label: 'Reunion' },
    { value: 'Romania', label: 'Romania' },
    { value: 'Russian Federation', label: 'Russian Federation' },
    { value: 'Rwanda', label: 'Rwanda' },
    { value: 'Saint Barthelemy', label: 'Saint Barthelemy' },
    { value: 'Saint Helena', label: 'Saint Helena' },
    { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
    { value: 'Saint Lucia', label: 'Saint Lucia' },
    { value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
    { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
    { value: 'Samoa', label: 'Samoa' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
    { value: 'Saudi Arabia', label: 'Saudi Arabia' },
    { value: 'Senegal', label: 'Senegal' },
    { value: 'Serbia', label: 'Serbia' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'Slovakia', label: 'Slovakia' },
    { value: 'Slovenia', label: 'Slovenia' },
    { value: 'Solomon Islands', label: 'Solomon Islands' },
    { value: 'Somalia', label: 'Somalia' },
    { value: 'South Africa', label: 'South Africa' },
    { value: 'South Georgia and the South Sandwich Islands', label: 'South Georgia and the South Sandwich Islands' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'Sudan', label: 'Sudan' },
    { value: 'Suriname', label: 'Suriname' },
    { value: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
    { value: 'Swaziland', label: 'Swaziland' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
    { value: 'Taiwan', label: 'Taiwan' },
    { value: 'Tajikistan', label: 'Tajikistan' },
    { value: 'Tanzania, United Republic of', label: 'Tanzania, United Republic of' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Timor-Leste', label: 'Timor-Leste' },
    { value: 'Togo', label: 'Togo' },
    { value: 'Tokelau', label: 'Tokelau' },
    { value: 'Tonga', label: 'Tonga' },
    { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'Turkmenistan', label: 'Turkmenistan' },
    { value: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
    { value: 'Tuvalu', label: 'Tuvalu' },
    { value: 'Uganda', label: 'Uganda' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'United States Minor Outlying Islands', label: 'United States Minor Outlying Islands' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Uzbekistan', label: 'Uzbekistan' },
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Viet Nam', label: 'Viet Nam' },
    { value: 'Virgin Islands, British', label: 'Virgin Islands, British' },
    { value: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
    { value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
    { value: 'Western Sahara', label: 'Western Sahara' },
    { value: 'Yemen', label: 'Yemen' },
    { value: 'Zambia', label: 'Zambia' },
    { value: 'Zimbabwe', label: 'Zimbabwe' },
    { value: 'Other', label: 'Other' }
  ]
};
