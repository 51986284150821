import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Table } from '@hexure/ui';

import Field from 'shared/Field';
import Input from 'shared/Input';
import Modal from 'shared/Modal';
import Select from 'shared/Select';

const HealthAnalyzerModal = ({ data, disableQuoter, onApply, onClose }) => {
  const [state, setState] = useState(data);
  const [hideCarrierList, setHideCarrierList] = useState(true);
  const [errors, setErrors] = useState([]);

  const handleDataChange = (name, e) => {
    const new_errors = [];
    const updated_state = {
      ...state,
      [name]: e.target.value
    };

    if (!updated_state.weight) {
      new_errors.push('weight');
    }

    if (updated_state.tobacco_type === 'Cigars' && updated_state.no_of_cigars < 1) {
      new_errors.push('no_of_cigars');
    }

    setErrors(new_errors);
    setState(updated_state);
  };

  const handleTobaccoChange = e => {
    const tobacco = e.target.value;

    setState({
      ...state,
      tobacco,
      tobacco_last_used: tobacco === 'true' ? 'within_last_year' : 'Never',
      tobacco_type: tobacco === 'true' ? 'Cigarettes' : 'None',
      no_of_cigars: 0
    });
  };

  const handleTobaccoLastUsedChange = e => {
    const tobacco_last_used = e.target.value;
    const updated_state = {
      ...state,
      tobacco_last_used
    };

    if (tobacco_last_used === 'Never') {
      updated_state.tobacco_type = 'None';
      updated_state.no_of_cigars = 0;
    }

    setState(updated_state);
  };

  const handleTobaccoTypeChange = e => {
    const tobacco_type = e.target.value;

    setState({
      ...state,
      tobacco_type,
      no_of_cigars: tobacco_type === 'Cigars' ? 1 : 0
    });
  };

  return hideCarrierList ? (
    <Modal
      buttons={[
        {
          children: 'Apply Health Information',
          disabled: !!errors.length,
          onClick: onApply.bind(null, state),
          style: { marginLeft: 'auto' }
        }
      ]}
      maxWidth={650}
      onClose={onClose.bind(null, null)}
      title='Health Analyzer'
    >
      <div style={{ width: 650, padding: '30px 30px 15px' }}>
        <Alert
          action={{
            label: 'View Supported Carriers',
            onClick: setHideCarrierList.bind(null, false)
          }}
          description='The Health Analyzer is not currently supported by all carriers on the quoting platform. Using the Health Analyzer will only return quotes from supported carriers.'
          title='Carrier Support'
          type='warning'
        />
        <div style={{ display: 'flex', columnGap: 20, marginTop: 20 }}>
          <Field label='Height' style={{ flex: 1 }}>
            <div style={{ display: 'flex', columnGap: 10 }}>
              <Select
                onChange={handleDataChange.bind(null, 'height_feet')}
                options={[
                  { value: '0', label: '0 ft' },
                  { value: '1', label: '1 ft' },
                  { value: '2', label: '2 ft' },
                  { value: '3', label: '3 ft' },
                  { value: '4', label: '4 ft' },
                  { value: '5', label: '5 ft' },
                  { value: '6', label: '6 ft' },
                  { value: '7', label: '7 ft' },
                  { value: '8', label: '8 ft' }
                ]}
                readOnly={disableQuoter}
                required={true}
                value={state.height_feet}
              />
              <Select
                onChange={handleDataChange.bind(null, 'height_inch')}
                options={[
                  { value: '0', label: '0 in' },
                  { value: '1', label: '1 in' },
                  { value: '2', label: '2 in' },
                  { value: '3', label: '3 in' },
                  { value: '4', label: '4 in' },
                  { value: '5', label: '5 in' },
                  { value: '6', label: '6 in' },
                  { value: '7', label: '7 in' },
                  { value: '8', label: '8 in' },
                  { value: '9', label: '9 in' },
                  { value: '10', label: '10 in' },
                  { value: '11', label: '11 in' }
                ]}
                readOnly={disableQuoter}
                required={true}
                value={state.height_inch}
              />
            </div>
          </Field>
          <Field invalid={errors.includes('weight')} label='Weight' style={{ flex: 1 }}>
            <Input
              invalid={errors.includes('weight')}
              max={1400}
              onChange={handleDataChange.bind(null, 'weight')}
              readOnly={disableQuoter}
              required={true}
              suffix='lbs'
              type='number'
              value={state.weight}
            />
          </Field>
        </div>

        <Field label='Is the proposed insured a tobacco user?'>
          <Select
            onChange={handleTobaccoChange}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
            readOnly={disableQuoter}
            value={state.tobacco}
          />
        </Field>
        {state.tobacco === 'true' && (
          <Field label='When was the last time they used tobacco?'>
            <Select
              onChange={handleTobaccoLastUsedChange}
              options={[
                { label: 'Within the last year', value: 'within_last_year' },
                { label: '1-2 Years', value: '1_2_Years' },
                { label: '2-3 Years', value: '2_3_Years' },
                { label: '4-5 Years', value: '4_5_Years' },
                { label: 'More than 5 years', value: 'More_than_5_years' }
              ]}
              readOnly={disableQuoter}
              required={true}
              value={state.tobacco_last_used}
            />
          </Field>
        )}
        {state.tobacco_last_used === 'Never' ? null : (
          <Field label={state.tobacco === 'true' ? 'What kind of tobacco do they use?' : 'What kind of tobacco did they use?'}>
            <Select
              onChange={handleTobaccoTypeChange}
              options={[{ value: 'Cigarettes' }, { value: 'Cigars' }, { value: 'Pipe' }, { value: 'Chewing Tobacco' }, { value: 'Nicotine Gum' }, { value: 'Nicotine Patch' }]}
              readOnly={disableQuoter}
              required={true}
              value={state.tobacco_type}
            />
          </Field>
        )}
        {state.tobacco_type === 'Cigars' ? (
          <Field invalid={errors.includes('no_of_cigars')} label={state.tobacco === 'true' ? 'How many cigars do they smoke each year?' : 'How many cigars did they smoke each year?'}>
            <Input invalid={errors.includes('no_of_cigars')} onChange={handleDataChange.bind(null, 'no_of_cigars')} readOnly={disableQuoter} step='1' type='number' value={state.no_of_cigars} />
          </Field>
        ) : null}
      </div>
    </Modal>
  ) : (
    <Modal
      buttons={[
        {
          color: '#e5e5e5',
          fontColor: '#444',
          children: 'Back To Health Analyzer',
          onClick: setHideCarrierList.bind(null, true)
        }
      ]}
      maxWidth={650}
      title='Supported Carriers'
    >
      <div style={{ width: 650, padding: '30px 30px 15px' }}>
        <Table
          columns={[
            {
              id: 'name',
              label: 'Carrier Name'
            }
          ]}
          data={[
            { name: 'American General Life Insurance' },
            { name: 'American National Insurance CompanyInsurance' },
            { name: 'Ameritas Life Insurance Corp.Insurance' },
            { name: 'Assurity Life Insurance CompanyInsurance' },
            { name: 'Cincinnati Life Insurance CompanyInsurance' },
            { name: 'Equitable Life Insurance CompanyInsurance' },
            { name: 'Foresters FinancialInsurance' },
            { name: 'John Hancock Life Insurance CompanyInsurance' },
            { name: 'Legal and General Life Insurance (Banner Life)Insurance' },
            { name: 'Lincoln National Life Insurance CompanyInsurance' },
            { name: 'Midland National Life Insurance CompanyInsurance' },
            { name: 'Nationwide Life and Annuity Insurance CompanyInsurance' },
            { name: 'North American Co for Life and HealthInsurance' },
            { name: 'Pacific Life Insurance CompanyPrincipalInsurance' },
            { name: 'National Life Insurance CompanyInsurance' },
            { name: 'Prosperity Life GroupInsurance' },
            { name: 'Protective Life Insurance CompanyInsurance' },
            { name: 'Prudential FinancialInsurance' },
            { name: 'Sagicor Life Insurance CompanyInsurance' },
            { name: 'Savings Bank Life Insurance Company (SBLI)Insurance' },
            { name: 'Securian Financial GroupInsurance' },
            { name: 'Sons of NorwayInsurance' },
            { name: 'Symetra Life Insurance CompanyInsurance' },
            { name: 'Transamerica Life Insurance CompanyInsurance' },
            { name: 'United of Omaha Life Insurance Company' }
          ]}
        />
      </div>
    </Modal>
  );
};

HealthAnalyzerModal.propTypes = {
  data: PropTypes.object,
  disableQuoter: PropTypes.bool,
  onApply: PropTypes.func,
  onClose: PropTypes.func
};

export default HealthAnalyzerModal;
