import React from 'react';
import PropTypes from 'prop-types';
import { Button as NewButton } from '@hexure/ui';
import { mdiChevronRight } from '@mdi/js';

import 'css/firelight.css';

import Button from 'shared/Button';
import Loader from 'shared/Loader';

import { Colors, FontFamilies, FontSizes, FontWeights } from 'constants/Clementine';

class FireLightEmbed extends React.Component {
  static propTypes = {
    activityId: PropTypes.string,
    activityType: PropTypes.string,
    environment: PropTypes.string,
    errorMessage: PropTypes.string,
    mobile: PropTypes.bool,
    onError: PropTypes.func,
    onStatusChange: PropTypes.func,
    status: PropTypes.string,
    successMessage: PropTypes.string,
    token: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      current_screen: '',
      show_mobile_menu: false,

      bundles: [],
      current_bundle_id: null,
      current_page_number: null,
      next_item_is_enabled: false,
      on_last_page: false,
      viewed_bundles: [],
      viewed_pages: [],
      show_firelight_errors: false,
      percent_complete: 0
    };

    this.firelightDiv = React.createRef();
  }

  componentDidMount() {
    this._loadFirelight();

    // Firelight takes a while to load with no loading indicator.
    // This is a hack to show a loader until the firelight div has child elements
    const checkLoading = setInterval(() => {
      const firelight_el = document.getElementById('fireLightMainDiv');

      if (firelight_el) {
        if (firelight_el.hasChildNodes()) {
          this.setState({ loading: false });
          clearInterval(checkLoading);
        }
      } else {
        clearInterval(checkLoading);
      }
    }, 300);
  }

  componentDidUpdate() {
    this._loadFirelight();
  }

  componentWillUnmount() {
    if (this.firelightDiv && this.firelightDiv.current) {
      this.firelightDiv.current.removeEventListener('fireLightLoaded', this._handleFirelightLoaded);
    }

    ['firelight_script', 'firelight_eapp_css', 'firelight_esign_css', 'firelight_flexbox_css', 'firelight_fa_css', 'firelight_fa_shim_css'].forEach(id => {
      const existing_el = document.getElementById(id);

      if (existing_el) {
        existing_el.parentNode.removeChild(existing_el);
      }
    });
  }

  _loadFirelight = () => {
    const { status } = this.props;
    const element = this.firelightDiv.current;

    if (element && ['sent', 'locked', 'signing'].includes(status)) {
      const fl_scripts = [{ id: 'firelight_script', url: `https://${this.props.environment}Resource/Scripts/eApp~eSign` }];
      const fl_stylesheets = [
        { id: 'firelight_eapp_css', url: `https://${this.props.environment}Resource/CSS/eApp` },
        { id: 'firelight_esign_css', url: `https://${this.props.environment}Resource/CSS/eSign` },
        { id: 'firelight_flexbox_css', url: 'https://cdnjs.cloudflare.com/ajax/libs/flexboxgrid/6.3.1/flexboxgrid.min.css' },
        { id: 'firelight_fa_css', url: 'https://use.fontawesome.com/releases/v5.1.1/css/all.css' },
        { id: 'firelight_fa_shim_css', url: 'https://use.fontawesome.com/releases/v5.1.1/css/v4-shims.css' }
      ];

      fl_scripts.forEach(item => {
        const existing_el = document.getElementById(item.id);

        if (!existing_el) {
          const el = document.createElement('script');

          el.src = item.url;
          el.id = item.id;

          document.body.appendChild(el);
        }
      });

      fl_stylesheets.forEach(item => {
        const existing_el = document.getElementById(item.id);

        if (!existing_el) {
          const el = document.createElement('link');

          el.href = item.url;
          el.rel = 'stylesheet';
          el.id = item.id;

          document.body.appendChild(el);
        }
      });

      element.addEventListener('fireLightLoaded', this._handleFirelightLoaded);
    }
  };

  _handleFirelightLoaded = () => {
    window.FireLightAPI.loadModule('EApp');
    window.FireLightAPI.init(this.props.token, this.props.activityId, true, true, false);
    window.FireLightAPI.errorEvent.subscribe(this._handleErrorEvent);
    window.FireLightAPI.statusEvent.subscribe(this._handleStatusEvent);
    window.FireLightAPI.navigationEvent.subscribe(this._handleNavigationEvent);
    window.FireLightAPI.signatureStatusEvent.subscribe(this._handleNavigationEvent);
    window.FireLightAPI.setShowAllValidationMessages(false);
  };

  _handleErrorEvent = ({ error }) => {
    if (this.props.onError) {
      this.props.onError(error.Message);
    }
  };

  _handleStatusEvent = event => {
    // fires on page navigation, input blur, checkbox/select change, file upload
    // if no fields/sections are required or the "accept not in good order applications" flag is set,
    // then the application is 100% complete by default
    this.setState({ percent_complete: event.percentComplete });
  };

  _handleNavigationEvent = nav_event => {
    const { navigationGraph, pageNumber } = nav_event;
    const { current_bundle_id, current_page_number, viewed_bundles, viewed_pages } = this.state;
    const visible_bundles = navigationGraph && navigationGraph.length > 0 ? navigationGraph.filter(b => b.IsVisible) : null;
    const bundle = navigationGraph.find(b => b.IsSelected);
    const visible_pages = bundle.Pages.filter(p => p.IsVisible);
    const last_bundle = visible_bundles[visible_bundles.length - 1];
    const current_page_index = visible_pages.findIndex(p => p.PageNumber === pageNumber);
    const next_page = visible_pages[current_page_index + 1];
    const current_bundle_index = visible_bundles.findIndex(b => b.BundleID === bundle.BundleID);
    const next_bundle = visible_bundles[current_bundle_index + 1];
    const next_item = next_page || next_bundle;

    // we want to store the bundle/page we're moving away from
    if (bundle.BundleID !== current_bundle_id) {
      viewed_bundles.push(current_bundle_id);
    }
    viewed_pages.push(current_page_number);

    // fires on page navigation, input blur, checkbox/select change, file upload
    this.setState(
      {
        bundles: navigationGraph,
        current_bundle_id: bundle.BundleID,
        current_page_number: pageNumber,
        next_item_is_enabled: next_item && next_item.IsEnabled,
        on_last_page: pageNumber === last_bundle.Pages[last_bundle.Pages.length - 1].PageNumber,
        viewed_bundles: [...new Set(viewed_bundles)],
        viewed_pages: [...new Set(viewed_pages)]
      },
      () => {
        // If we try calling this anytime sooner, than we don't get the navigation graph to display
        if (this.props.status === 'signing') {
          this._collectSignatures();
        }
      }
    );
  };

  _handlePageClick = page_number => {
    if (this.props.mobile) {
      this.setState({ show_mobile_menu: false });
    }

    window.FireLightAPI.navigateToPage(page_number);
  };

  _handleBackClick = () => {
    window.FireLightAPI.navigateToPagePrevious();
  };

  _handleNextClick = () => {
    window.FireLightAPI.navigateToPageNext();
  };

  _toggleFirelightErrors = () => {
    const show_firelight_errors = !this.state.show_firelight_errors;

    this.setState({ show_firelight_errors });

    window.FireLightAPI.setShowAllValidationMessages(show_firelight_errors);
  };

  _showFirelightPdfDialog = () => {
    window.FireLightAPI.renderPDF();
  };

  _showFirelightUploadDialog = () => {
    if (this.props.mobile) {
      this.setState({ show_mobile_menu: false });
    }

    window.FireLightAPI.showDocumentsDialog();
  };

  _collectSignatures = () => {
    window.FireLightAPI.proceedToSignatures();
    window.FireLightAPI.signatureStatusEvent.subscribe(this._handleSignatureStatusEvent);
  };

  _handleSignatureStatusEvent = event => {
    if (event.succeeded) {
      window.FireLightAPI.loadModule('eSign');
      window.FireLightAPI.loadEmbeddedESign(this.props.activityId);
      window.FireLightAPI.eSignStatusEvent.subscribe(this._handleSignatureStatusEvent);
      return;
    }
    let status = this.props.status;

    switch (event.status) {
      case 0: // Route Change
        if (event.page === 10) {
          // for e_app this means signing is complete
          status = 'completed';

          this.props.onStatusChange(status);

          window.FireLightAPI.loadModule('EApp');
          // this is needed to get the application in it's locked state
          window.FireLightAPI.init(this.props.token, this.props.activityId, false, true, false);
        }

        break;
      case 1: // ESignInitialized
        status = 'signing';

        // Update resource - signing in e_app, souldn't ever get to this in an e-ticket

        this.props.onStatusChange(status);

        break;
      case 2: // ESignAccepted
        status = 'signing';

        // Update resource - signing in e_app, souldn't ever get to this in an e-ticket

        this.props.onStatusChange(status);

        break;
      case 3: // ESignDeclined
        status = 'locked';

        // Update resource - signing in e_app, souldn't ever get to this in an e-ticket
        this.props.onStatusChange(status);

        window.FireLightAPI.loadModule('EApp');
        // this is needed to get the application in it's locked state
        window.FireLightAPI.init(this.props.token, this.props.activityId, false, true, false);

        break;
      case 5: // ESignNowComplete
        // This is fired after EACH signer has completed their signatures
        break;
      case 6: // ESignSignatureDeclined
        // This is similar to 3, it just happens during the signing process if the user declines to do electronic signatures
        status = 'locked';

        // Update resource - signing in e_app, souldn't ever get to this in an e-ticket
        this.props.onStatusChange(status);

        window.FireLightAPI.loadModule('EApp');
        // this is needed to get the application in it's locked state
        window.FireLightAPI.init(this.props.token, this.props.activityId, false, true, false);

        break;
      case 8: // ActivitySubmitted
        // Activity auto-submitted, used for activities that don't have signing

        status = 'completed';

        this.props.onStatusChange(status);

        break;
    }
  };

  render() {
    const { bundles, current_page_number, loading, next_item_is_enabled, on_last_page, percent_complete, show_firelight_errors, viewed_bundles, viewed_pages } = this.state;
    const { activityType, mobile, status, successMessage } = this.props;
    const styles = this.styles();

    return (
      <div style={Object.assign({}, styles.container, mobile && styles.mobile_container)}>
        {status === 'completed' ? (
          <div style={styles.message_content}>
            <div style={styles.icon_wrapper}>
              <i className='mdi mdi-checkbox-marked-circle-outline' style={styles.icon} />
            </div>
            <div style={styles.title}>Success</div>
            <div style={styles.copy}>{successMessage}</div>
            {activityType === 'e_app' ? (
              <div style={{ display: 'flex' }}>
                <Button onClick={this._showFirelightPdfDialog} style={{ marginLeft: 10 }}>
                  Download PDF Files
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}

        {status === 'error' ? (
          <div style={styles.message_content}>
            <div style={styles.icon_wrapper}>
              <i className='mdi mdi-alert-outline' style={styles.icon} />
            </div>
            <div style={styles.title}>An error occurred while trying to display FireLight</div>
            <div style={styles.copy}>{this.props.errorMessage}</div>
          </div>
        ) : null}

        {['sent', 'locked', 'signing'].includes(status) && loading ? <div style={styles.loading}><Loader /></div> : null}
        {mobile ? (
          <>
            {['sent'].includes(status) ? (
              <div style={styles.mobile_menu}>
                <div style={styles.mobile_menu_header}>
                  <div style={styles.mobile_menu_product_name}>[Product Name Goes Here]</div>
                  <div style={styles.mobile_menu_carrier_name}>[Carrier Name]</div>
                </div>
                <div style={styles.menu_nav}>
                  {bundles.map(({ BundleID, BundleName, HasErrors, IsEnabled, IsSelected, IsVisible, Pages }) => {
                    const first_page = Pages.find(p => p.IsVisible && p.IsEnabled);
                    const has_been_viewed = viewed_bundles.includes(BundleID);
                    const is_active = IsSelected;
                    const is_clickable = first_page && IsEnabled;

                    return IsVisible ? (
                      <div key={BundleID} style={styles.bundle}>
                        <div
                          onClick={is_clickable ? this._handlePageClick.bind(null, first_page.PageNumber) : null}
                          style={Object.assign({}, styles.bundle_label, is_clickable && styles.bundle_label_enabled)}
                        >
                          <div style={Object.assign({}, styles.bundle_title, is_active && styles.bundle_title_active)}>
                            {BundleName}
                          </div>
                          {has_been_viewed && HasErrors ? (
                            <i className='mdi mdi-alert-circle' style={styles.bundle_error_badge} />
                          ) : null}
                          {has_been_viewed && !HasErrors ? (
                            <i className='mdi mdi-checkbox-marked-circle-outline' style={styles.bundle_complete_badge} />
                          ) : null}
                        </div>

                        {IsSelected ? (
                          <div style={styles.pages}>
                            {Pages.map(({ PageNumber, PageName, IsEnabled, IsSelected, IsVisible, HasErrors }) => {
                              const has_been_viewed = viewed_pages.includes(PageNumber);
                              let status_styles = {};

                              if (has_been_viewed && !IsSelected) {
                                if (HasErrors) {
                                  status_styles = styles.page_errors;
                                } else {
                                  status_styles = styles.page_complete;
                                }
                              }

                              return IsVisible ? (
                                <div
                                  key={`page_${PageNumber}`}
                                  onClick={IsEnabled ? this._handlePageClick.bind(null, PageNumber) : null}
                                  style={Object.assign(
                                    {},
                                    styles.page,
                                    status_styles,
                                    IsEnabled && styles.page_enabled,
                                    IsSelected && styles.page_active
                                  )}
                                >
                                  {PageName}

                                  {HasErrors ? (
                                    <i className='mdi mdi-alert-circle' style={styles.page_error_badge} />
                                  ) : null}
                                </div>
                              ) : null;
                            })}
                          </div>
                        ) : null}
                      </div>
                    ) : null;
                  })}

                  {['sent'].includes(status) && activityType === 'e_app' ? (
                    <div style={styles.bundle}>
                      <div
                        onClick={percent_complete === 100 ? this._collectSignatures : null}
                        style={Object.assign(
                          {},
                          styles.bundle_label,
                          percent_complete === 100 && styles.bundle_label_enabled
                        )}
                      >
                        <div style={styles.bundle_title}>
                          E-Sign
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div style={styles.mobile_page_nav}>
                  <i className='mdi mdi-backburger' onClick={() => this.setState({ show_mobile_menu: false })} style={styles.mobile_menu_toggle} />
                  <div style={styles.mobile_buttons}>
                    <NewButton format='secondary' onClick={this._showFirelightUploadDialog}>Upload Files</NewButton>
                  </div>
                </div>
              </div>
            ) : null}

            {status === 'sent' ? (
              <div style={styles.mobile_toolbar}>
                <div style={styles.mobile_progress_percent}>{percent_complete}% Complete</div>
                <div style={styles.mobile_progress_bar}><div style={styles.progress} /></div>
              </div>
            ) : null}

            {['sent', 'locked', 'signing', 'completed'].includes(status) ? (
              <div style={styles.mobile_content}>
                {status === 'locked' ? (
                  <div style={styles.locked_notice}>
                    <i className='mdi mdi-lock' style={styles.locked_notice_icon} />
                    <div style={styles.locked_notice_copy}>
                      This application has been locked as a result of declining to use e-sign. You must collect wet signatures to complete this application.
                    </div>
                  </div>
                ) : null}

                <div style={styles.firelight}>
                  <div id='fireLightMainDiv' ref={this.firelightDiv} />
                </div>
              </div>
            ) : null}

            {['sent'].includes(status) ? (
              <div style={styles.mobile_page_nav}>
                <i className='mdi mdi-menu' onClick={() => this.setState({ show_mobile_menu: true })} style={styles.mobile_menu_toggle} />
                <div style={styles.mobile_buttons}>
                  {(activityType === 'e_app' && percent_complete < 100) || activityType === 'e_ticket' ? (
                    <>
                      <NewButton
                        disabled={current_page_number === 0}
                        format='secondary'
                        onClick={this._handleBackClick}
                      >
                        Back
                      </NewButton>

                      <NewButton
                        disabled={on_last_page ? percent_complete < 100 : !next_item_is_enabled}
                        icon={mdiChevronRight}
                        onClick={on_last_page ? this._collectSignatures : this._handleNextClick}
                      >
                        {on_last_page && activityType === 'e_ticket' ? 'Submit' : 'Next'}
                      </NewButton>
                    </>
                  ) : null}

                  {activityType === 'e_app' && percent_complete === 100 ? (
                    <NewButton
                      icon={mdiChevronRight}
                      onClick={this._collectSignatures}
                    >
                      Collect Signatures
                    </NewButton>
                  ) : null}
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>

            <div style={styles.application}>
              {status === 'sent' ? (
                <div style={styles.toolbar}>
                  <div style={styles.toolbar_content}>
                    <div style={styles.progress_percent}>{percent_complete}% Complete</div>
                    <div style={styles.toolbar_actions}>
                      <Button color={show_firelight_errors ? Colors.RED.hex : '#d8d8d8'} icon='alert-circle-outline' onClick={this._toggleFirelightErrors} small={true} style={styles.action_btn} />
                      <div style={styles.action_divider} />
                      <Button icon='printer' onClick={this._showFirelightPdfDialog} small={true} style={styles.action_btn}>
                        Print PDFs
                      </Button>
                      <Button icon='upload' onClick={this._showFirelightUploadDialog} small={true} style={styles.action_btn}>
                        Upload Files
                      </Button>
                      {activityType === 'e_app' ? (
                        <Button disabled={percent_complete < 100} icon='file-sign' onClick={this._collectSignatures} small={true} style={styles.action_btn}>
                          Collect Signatures
                        </Button>
                      ) : null}
                    </div>
                  </div>
                  <div style={styles.progress_bar}>
                    <div style={styles.progress} />
                  </div>
                </div>
              ) : null}

              {status === 'locked' ? (
                <div style={styles.toolbar}>
                  <div style={styles.toolbar_content}>
                    <div style={styles.toolbar_actions}>
                      <Button icon='printer' onClick={this._showFirelightPdfDialog} small={true}>
                        Print PDFs
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}

              {['sent', 'locked', 'signing', 'completed'].includes(status) ? (
                <div style={styles.content}>
                  {status === 'locked' ? (
                    <div style={styles.locked_notice}>
                      <i className='mdi mdi-lock' style={styles.locked_notice_icon} />
                      <div style={styles.locked_notice_copy}>This application has been locked as a result of declining to use e-sign. You must collect wet signatures to complete this application.</div>
                    </div>
                  ) : null}

                  <div style={styles.firelight}>
                    <div id='fireLightMainDiv' ref={this.firelightDiv}>
                      <Loader />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {['sent', 'locked', 'signing'].includes(status) ? (
              <div style={styles.menu}>
                <div style={styles.menu_nav}>
                  {bundles.map(({ BundleID, BundleName, HasErrors, IsEnabled, IsSelected, IsVisible, Pages }) => {
                    const first_page = Pages.find(p => p.IsVisible && p.IsEnabled);
                    const has_been_viewed = viewed_bundles.includes(BundleID) || ['signing', 'locked'].includes(status);
                    const is_active = IsSelected && !['signing'].includes(status);
                    const is_clickable = first_page && IsEnabled && !['signing'].includes(status);

                    return IsVisible ? (
                      <div key={BundleID} style={styles.bundle}>
                        <div
                          onClick={is_clickable ? this._handlePageClick.bind(null, first_page.PageNumber) : null}
                          style={Object.assign({}, styles.bundle_label, is_clickable && styles.bundle_label_enabled)}
                        >
                          <div style={Object.assign({}, styles.bundle_title, is_active && styles.bundle_title_active)}>{BundleName}</div>
                          {has_been_viewed && HasErrors ? <i className='mdi mdi-alert-circle' style={styles.bundle_error_badge} /> : null}
                          {has_been_viewed && !HasErrors ? <i className='mdi mdi-checkbox-marked-circle-outline' style={styles.bundle_complete_badge} /> : null}
                        </div>

                        {IsSelected && !['signing'].includes(status) ? (
                          <div style={styles.pages}>
                            {Pages.map(({ PageNumber, PageName, IsEnabled, IsSelected, IsVisible, HasErrors }) => {
                              const has_been_viewed = viewed_pages.includes(PageNumber) || status === 'locked';
                              let status_styles = {};

                              if (has_been_viewed && !IsSelected) {
                                if (HasErrors) {
                                  status_styles = styles.page_errors;
                                } else {
                                  status_styles = styles.page_complete;
                                }
                              }

                              return IsVisible ? (
                                <div
                                  key={`page_${PageNumber}`}
                                  onClick={IsEnabled ? this._handlePageClick.bind(null, PageNumber) : null}
                                  style={Object.assign({}, styles.page, status_styles, IsEnabled && styles.page_enabled, IsSelected && styles.page_active)}
                                >
                                  {PageName}

                                  {HasErrors ? <i className='mdi mdi-alert-circle' style={styles.page_error_badge} /> : null}
                                </div>
                              ) : null;
                            })}
                          </div>
                        ) : null}
                      </div>
                    ) : null;
                  })}

                  {['sent', 'signing'].includes(status) && activityType === 'e_app' ? (
                    <div style={styles.bundle}>
                      <div
                        onClick={percent_complete === 100 && !['signing'].includes(status) ? this._collectSignatures : null}
                        style={Object.assign({}, styles.bundle_label, percent_complete === 100 && !['signing'].includes(status) && styles.bundle_label_enabled)}
                      >
                        <div style={Object.assign({}, styles.bundle_title, ['signing'].includes(status) && styles.bundle_title_active)}>E-Sign</div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {['sent'].includes(status) ? (
                  <div style={styles.menu_buttons}>
                    <Button disabled={current_page_number === 0} onClick={this._handleBackClick} outline={true}>
                      Back
                    </Button>

                    <Button
                      disabled={on_last_page ? percent_complete < 100 : !next_item_is_enabled}
                      onClick={on_last_page ? this._collectSignatures : this._handleNextClick}
                      style={{ marginLeft: 10 }}
                    >
                      {on_last_page && activityType === 'e_ticket' ? 'Submit' : 'Next'}
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  }

  styles = () => {
    const { status, mobile } = this.props;
    const show_mobile_menu = mobile && this.state.show_mobile_menu;
    const hide_app = ['error', 'completed'].includes(status);

    return {
      loading: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: '#fff',
        zIndex: 100
      },
      container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch'
      },
      mobile_container: {
        flexDirection: 'column'
      },
      application: {
        flex: hide_app ? 0 : 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: hide_app ? 0 : 620, // this prevents the toolbar from getting jumbled
        width: hide_app ? 0 : 'auto',
        overflow: hide_app ? 'hidden' : 'visible'
      },
      mobile_application: {
        display: 'flex',
        flexDirection: 'column'
      },

      // TOOLBAR
      toolbar: {
        background: '#f5f5f5',
        flexShrink: 0
      },
      toolbar_content: {
        padding: 10,
        borderBottom: '1px solid #e5e5e5',
        display: 'flex',
        alignItems: 'center'
      },
      progress_percent: {
        fontSize: FontSizes.LARGE,
        fontWeight: FontWeights.MEDIUM,
        paddingLeft: 10
      },
      toolbar_actions: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
      },
      action_btn: {
        marginLeft: 5
      },
      action_divider: {
        width: 1,
        height: 28,
        background: '#ddd',
        margin: '0px 10px 0px 15px'
      },
      progress_bar: {
        height: 4,
        background: '#fff',
        display: 'flex',
        alignItems: 'stretch'
      },
      progress: {
        background: Colors.GREEN.hex,
        width: `${this.state.percent_complete}%`,
        transition: 'all .5s'
      },

      // MOBILE TOOLBAR
      mobile_toolbar: {
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        alignItems: 'flex-start',
        gap: 4
      },
      mobile_progress_percent: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1
      },
      mobile_progress_bar: {
        height: 8,
        borderRadius: 4,
        background: '#e5e5e5',
        alignSelf: 'stretch',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'stretch'
      },

      content: {
        height: 'calc(100vh - 180px)',
        width: 'calc(100vw - 440px)',
        overflow: 'auto',
        minWidth: '100%'
      },
      mobile_content: {
        overflow: 'auto',
        height: status === 'sent' ? 'calc(100vh - 120px)' : 'auto'
      },
      firelight: {
        padding: 20
      },
      locked_notice: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 30px',
        background: Colors.GRAY.hex,
        position: 'sticky',
        top: 0,
        zIndex: 1
      },
      locked_notice_icon: {
        color: '#fff',
        fontSize: 26,
        marginRight: 10
      },
      locked_notice_copy: {
        fontSize: FontSizes.MEDIUM,
        color: 'rgba(255,255,255,0.65)'
      },

      // MENU
      menu: {
        width: 400,
        height: 'calc(100vh - 125px)',
        flexShrink: 0,
        borderLeft: '1px solid #e5e5e5',
        display: 'flex',
        flexDirection: 'column'
      },
      mobile_menu: {
        position: 'fixed',
        inset: '0px 40px 0px 0px',
        background: '#fff',
        boxShadow: '5px 0px 40px 0px rgba(0,0,0,0.6)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        transition: 'all .2s',
        transform: show_mobile_menu ? 'translateX(0%)' : 'translateX(-100%)',
        opacity: show_mobile_menu ? 1 : 0,
        zIndex: 100
      },
      mobile_menu_header: {
        display: 'flex',
        padding: '20px 20px 0px 20px',
        flexDirection: 'column',
        gap: 4
      },
      mobile_menu_product_name: {
        fontSize: 18,
        lineHeight: '24px'
      },
      mobile_menu_carrier_name: {
        fontSize: 14,
        lineHeight: '22px'
      },
      mobile_menu_footer: {
        display: 'flex',
        padding: '12px 12px 12px 0px',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
      menu_nav: {
        flex: 1,
        padding: 20,
        overflow: 'auto'
      },
      bundle: {
        marginBottom: 10
      },
      bundle_label: {
        background: '#f5f5f5',
        borderRadius: 4,
        padding: 12,
        display: 'flex',
        alignItems: 'center'
      },
      bundle_label_enabled: {
        cursor: 'pointer'
      },
      bundle_title: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.MEDIUM,
        lineHeight: '18px',
        opacity: 0.4
      },
      bundle_title_active: {
        opacity: 1
      },
      bundle_error_badge: {
        fontSize: 22,
        height: 18,
        marginTop: -4,
        marginLeft: 'auto',
        color: Colors.RED.hex
      },
      bundle_complete_badge: {
        fontSize: 22,
        height: 18,
        marginTop: -4,
        marginLeft: 'auto',
        color: Colors.GREEN.hex
      },
      pages: {
        marginTop: 10
      },
      page: {
        marginLeft: 12,
        marginBottom: 1,
        borderLeft: '2px solid #d8d8d8',
        padding: 12,
        fontSize: FontSizes.MEDIUM,
        lineHeight: '18px',
        color: '#999',
        display: 'flex',
        alignItems: 'center'
      },
      page_enabled: {
        cursor: 'pointer'
      },
      page_active: {
        borderLeftColor: '#d8d8d8',
        fontWeight: FontWeights.MEDIUM,
        background: '#f5f5f5'
      },
      page_errors: {
        borderLeftColor: Colors.RED.hex
      },
      page_error_badge: {
        fontSize: 22,
        height: 16,
        marginTop: -2,
        marginLeft: 'auto',
        color: Colors.RED.hex
      },
      page_complete: {
        borderLeftColor: Colors.GREEN.hex
      },

      menu_buttons: {
        padding: 20,
        flexShrink: 0,
        alignSelf: 'flex-end',
        display: 'flex',
        alignItems: 'flex-end'
      },
      mobile_page_nav: {
        display: 'flex',
        padding: '12px 0px',
        alignSelf: 'stretch'
      },
      mobile_menu_toggle: {
        padding: '0px 20px',
        alignSelf: 'stretch',
        fontSize: 30,
        display: 'flex',
        alignItems: 'center',
        color: '#0193D7'
      },
      mobile_buttons: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        padding: '0px 10px',
        flex: 1,
        justifyContent: 'flex-end'
      },
      message_content: {
        padding: '60px 40px',
        maxWidth: 550,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      },
      icon_wrapper: {
        width: 120,
        height: 120,
        borderRadius: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 40,
        background: status === 'error' ? `rgba(${Colors.RED.rgb}, 0.2)` : `rgba(${Colors.GREEN.rgb}, 0.2)`
      },
      icon: {
        fontSize: 42,
        color: status === 'error' ? Colors.RED.hex : Colors.GREEN.hex
      },
      title: {
        fontSize: FontSizes.XLARGE,
        fontFamily: FontFamilies.ACCENT,
        fontWeight: FontWeights.BOLD,
        marginBottom: 20
      },
      copy: {
        fontSize: FontSizes.MEDIUM,
        lineHeight: '24px',
        marginBottom: 20,
        textAlign: 'center'
      }
    };
  };
}

export default FireLightEmbed;
