import React from 'react';
import PropTypes from 'prop-types';

import Field from 'shared/Field';
import Input from 'shared/Input';
import Select from 'shared/Select';

import { YES_NO } from 'constants/Eticket';

const GeneralQuestions = ({ errors, formData, onInputChange }) => {
  return (
    <React.Fragment>
      <Field label='Do you currently have a pending felony charge against you?'>
        <Select
          invalid={errors.includes('pending_felony_change')}
          onChange={onInputChange.bind(null, 'pending_felony_change', parent_scope => {
            if (parent_scope.state.form_data.pending_felony_change === 'yes') {
              parent_scope.setState({
                hard_stop: true,
                hard_stop_attribute: 'pending_felony_change',
                hard_stop_reason: 'The proposed insured has a pending felony charge against them.'
              });
            }
          })}
          options={[{ value: '', label: '-- Select One --' }].concat(YES_NO)}
          required={true}
          value={formData.pending_felony_change}
        />
      </Field>

      <Field label='Are you currently on probation?'>
        <Select
          invalid={errors.includes('on_probation')}
          onChange={onInputChange.bind(null, 'on_probation', parent_scope => {
            if (parent_scope.state.form_data.on_probation === 'yes') {
              parent_scope.setState({
                hard_stop: true,
                hard_stop_attribute: 'on_probation',
                hard_stop_reason: 'The proposed insured is currently on probation.'
              });
            }
          })}
          options={[{ value: '', label: '-- Select One --' }].concat(YES_NO)}
          required={true}
          value={formData.on_probation}
        />
      </Field>

      {[
        {
          label: 'Have you been convicted or plead guilty or no contest to operating while under the influence of drugs or alcohol?',
          id: 'operating_under_influence'
        },
        {
          label: 'Have you plead guilty to or been convicted of any moving violations or had your drivers license suspended or revoked in the past three years?',
          id: 'moving_violation_suspended_revoked_license_last_3_years'
        },
        {
          label: 'Have you ever had life insurance declined, modified, or rates?',
          id: 'declined_life_insurance'
        },
        {
          label: 'Have you been diagnosed by a member of the medical profession for a heart condition, stroke or cancer?',
          id: 'diagnosed_heart_condition_stroke_cancer'
        },
        {
          label: 'Are you a member or do you intend to become a member of the armed forces, including the reserve?',
          id: 'armed_forces_member'
        },
        {
          label: 'Has either parent died from coronary artery disease, diabetes or cancer prior to the age of 60?',
          id: 'prior_to_60_parent_death'
        },
        {
          label: 'Have any siblings died from coronary artery disease, diabetes or cancer prior to the age of 60?',
          id: 'prior_to_60_sibling_death'
        },
        {
          label: 'In the next two years, do you intend to engage in motor sports, rock climbing/mountaineering, scuba diving or aviation related sports?',
          id: 'dangerous_sport_participation'
        },
        {
          label: 'Do you currently or intend to fly as a pilot or crew member in the next two years?',
          id: 'flying_participation'
        },
        {
          label: 'In the past two years, have you filed for personal or business bankruptcy?',
          id: 'recent_bankruptcy'
        },
        {
          label: 'In the next two years do you intend to travel or reside outside the US or Canada?',
          id: 'international_travel'
        }
      ].map(question => {
        return (
          <React.Fragment key={question.id}>
            <Field label={question.label}>
              <Select
                invalid={errors.includes(question.id)}
                onChange={onInputChange.bind(null, question.id, null)}
                options={[{ value: '', label: '-- Select One --' }].concat(YES_NO)}
                required={true}
                value={formData[question.id]}
              />
            </Field>

            {formData[question.id] === 'yes' ? (
              <Field label='Please provide details'>
                <Input
                  invalid={errors.includes(`${question.id}_details`)}
                  onChange={onInputChange.bind(null, `${question.id}_details`, null)}
                  required={true}
                  style={{ height: 80 }}
                  type='textarea'
                  value={formData[`${question.id}_details`]}
                />
              </Field>
            ) : null}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

GeneralQuestions.propTypes = {
  errors: PropTypes.array,
  formData: PropTypes.object,
  onInputChange: PropTypes.func
};

export default GeneralQuestions;
