import React from 'react';
import PropTypes from 'prop-types';

//import AgentLoginForm from 'alq/AgentLoginForm';

import Button from 'shared/Button';
import Modal from 'shared/Modal';
import FireLightEmbed from 'shared/FireLightEmbed';

import { Colors, FontFamilies, FontSizes, FontWeights } from 'constants/Clementine';

import AlqApi from 'utils/AlqApi';

import { ProductContext } from 'alq/Context';
import CustomLoader from '../shared/CustomLoader';

class FirelightEticketModal extends React.Component {
  static propTypes = {
    action: PropTypes.object.isRequired,
    eticket: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      current_view: 'loading',
      eticket: props.eticket,
      horizonLoading: null,
      horizonLinkError: false
    };
  }

  componentDidMount() {
    const { eticket } = this.state;
    //const { agent } = this.context;

    //if (agent && agent.id) {
    if (eticket) {
      this.setState({ current_view: 'application' });
    } else {
      this._createTicket();
    }
    //} else {
    //this.setState({ current_view: 'login' });
    //}
  }

  _createTicket = () => {
    const { agent, client, alq } = this.context;
    const { quote } = this.props;

    this.setState({
      current_view: 'loading'
    });

    AlqApi.createEticket({
      agency_id: alq.agency_id,
      created_member_id: agent.id ? agent.id : null,
      source_type: 'BgaQuoter',
      source_guid: window.IXN_QUOTER_CONFIG.id,
      provider: 'firelight',
      provider_status: 'queued',
      quote_guid: quote.guid,
      form_fields: JSON.stringify({
        // this should contain any data we want to pre-fill
        pi_first_name: client.first_name,
        pi_last_name: client.last_name,
        payment_mode: 'monthly'
      })
    })
      .then(({ data }) => {
        this.props.onSave(data);

        this.setState({
          eticket: data,
          current_view: 'application'
        });
      })
      .catch(() => {
        this.setState({ current_view: 'error' });
      });
  };

  _handleFireLightStatusChange = status => {
    AlqApi.updateEticket(this.state.eticket.guid, { provider_status: status })
      .then(({ data }) => {
        this.props.onSave(data);
        this.setState({ eticket: data });
        if (status === 'completed') {
          const eticketId = data.id;
          this.setState({
            horizonLoading: true
          });
          const waitForHorizonUrl = new Promise((resolve, reject) => {
            const startTime = Date.now();
            const checkHorizonUrl = () => {
              AlqApi.getHorizonsLink(eticketId)
                .then(({ data }) => {
                  if (data.horizons_url) {
                    window.location.href = data.horizons_url;
                    this.setState({
                      horizonLoading: false
                    });
                    resolve();
                  } else if (Date.now() - startTime < 30000) {
                    setTimeout(checkHorizonUrl, 5000);
                  } else {
                    this.setState({ current_view: 'horizonLinkError', horizonLinkError: true });
                    reject('Horizon URL not available after 30 seconds');
                  }
                })
                .catch(() => {
                  this.setState({ current_view: 'horizonLinkError', horizonLinkError: true });
                  reject('Error while fetching Horizon URL');
                });
            };
            setTimeout(checkHorizonUrl, 10000);
          });

          Promise.race([waitForHorizonUrl, new Promise(resolve => setTimeout(resolve, 30000))]).catch(() => {
            this.setState({ current_view: 'horizonLinkError' });
          });
        }
      })
      .catch(() => {
        this.setState({ current_view: 'updateEticketError' });
      });
  };

  _handleFireLightError = error_msg => {
    this.setState({ current_view: 'loading' });

    AlqApi.updateEticket(this.state.eticket.id, { provider_status: 'error', provider_response: error_msg }).then(({ data }) => {
      this.props.onSave(data);
      this.setState({ current_view: 'application', eticket: data });
    });
  };

  render() {
    const styles = this.styles();
    const { action, onClose } = this.props;
    const { current_view, eticket, horizonLoading, horizonLinkError } = this.state;
    const fl_environment = eticket ? JSON.parse(eticket.form_fields).find(f => f.label === 'fl_environment').value : null;

    if (horizonLoading && !horizonLinkError) {
      return <CustomLoader horizonLinkError={horizonLinkError} message='Fetching Horizon Link ..... ' />;
    } else if (horizonLoading && horizonLinkError) {
      return <CustomLoader horizonLinkError={horizonLinkError} message='We are unable to fetch horizon link at this moment' />;
    } else {
      return (
        <Modal fullScreen={true} loading={current_view === 'loading'} maxWidth='none' onClose={onClose} title={action.label}>
          {current_view === 'application' && (
            <FireLightEmbed
              activityId={eticket.provider_tracking_code}
              activityType='e_ticket'
              environment={fl_environment}
              errorMessage={eticket.provider_response}
              onError={this._handleFireLightError}
              onStatusChange={this._handleFireLightStatusChange}
              status={eticket.provider_status}
              successMessage='Your application has been completed and will be auto-submitted to the carrier. The carrier will coordinate with you or your agency if any additional steps are required.'
              token={eticket.token}
            />
          )}

          {current_view === 'error' && (
            <div style={styles.message_content}>
              <div style={styles.icon_wrapper}>
                <i className='mdi mdi-alert-outline' style={styles.icon} />
              </div>
              <div style={styles.title}>An error occurred while trying to display FireLight</div>
              <div style={styles.copy}>We are unable to start an e-ticket at this time. Please contact support for further assistance.</div>
              <Button onClick={onClose}>Back To Quote Results</Button>
            </div>
          )}
        </Modal>
      );
    }
  }

  styles = () => {
    return {
      content: {
        maxWidth: 550,
        height: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      },
      icon_wrapper: {
        width: 120,
        height: 120,
        borderRadius: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 40,
        background: `rgba(${Colors.RED.rgb}, 0.2)`
      },
      icon: {
        fontSize: 42,
        color: Colors.RED.hex
      },
      title: {
        fontSize: FontSizes.XLARGE,
        fontFamily: FontFamilies.ACCENT,
        fontWeight: FontWeights.BOLD,
        marginBottom: 10
      },
      copy: {
        fontSize: FontSizes.MEDIUM,
        lineHeight: '24px',
        marginBottom: 20,
        textAlign: 'center'
      }
    };
  };
}

FirelightEticketModal.contextType = ProductContext;

export default FirelightEticketModal;
