import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';

import { FontWeights, FontSizes, Colors } from 'constants/Clementine';

const QuotePremiums = ({ quote }) => {
  const styles = {
    component: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch'
    },
    payment_mode: {
      width: '24%',
      border: '1px solid #e5e5e5',
      borderRadius: 3,
      position: 'relative'
    },
    title: {
      fontSize: FontSizes.MEDIUM,
      textTransform: 'capitalize',
      textAlign: 'center',
      padding: '10px 0',
      borderBottom: '1px solid #e5e5e5',
      marginBottom: '-1px'
    },
    line_items: {
      borderBottom: '1px solid #e5e5e5',
      padding: 10
    },
    line_item: {
      fontSize: FontSizes.MEDIUM,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirectin: 'column',
      marginBottom: '10px'
    },
    amount: {
      display: 'inline-block',
      width: 50,
      flexShrink: 0,
      fontWeight: FontWeights.BOLD,
      textAlign: 'right'
    },
    total: {
      fontSize: FontSizes.XLARGE,
      fontWeight: FontWeights.MEDIUM,
      textAlign: 'center',
      padding: '15px 0'
    },
    not_available: {
      fontSize: FontSizes.MEDIUM,
      color: Colors.GRAY.hex,
      lineHeight: '1.3em',
      fontStyle: 'italic',
      padding: '20px',
      textAlign: 'center',
      width: '100%'
    }
  };
  const payment_modes = ['monthly', 'quarterly', 'semi_annual', 'annual'];
  const line_items = [
    {
      label: 'Base: ',
      name: 'base_premium',
      is_estimate: quote.modal_policy_fee_estimated
    },
    {
      label: 'Policy Fee: ',
      name: 'policy_fee',
      is_estimate: quote.modal_policy_fee_estimated
    },
    {
      label: `Table Rate ${quote.table_rate_letter}:`,
      name: 'table_rate'
    },
    {
      label: 'Flat Extra: ',
      name: 'flat_extra'
    },
    {
      label: 'Child Rider: ',
      name: 'child_rider'
    },
    {
      label: 'WOP Rider: ',
      name: 'wop_rider'
    },
    {
      label: 'Child Rider/WOP: ',
      name: 'child_wop_rider'
    },
    {
      label: 'ADB Rider: ',
      name: 'adb_rider'
    }
  ];

  return (
    <div style={styles.component}>
      {payment_modes.map(payment_mode => {
        return (
          <div key={payment_mode} style={styles.payment_mode}>
            <div style={styles.title}>{payment_mode.replace('_', ' ')}</div>

            {quote[`premium_${payment_mode}`] ? (
              <div>
                {quote.hide_premium_breakdown || quote[`premium_${payment_mode}`] === quote[`base_premium_${payment_mode}`] ? null : (
                  <div style={styles.line_items}>
                    {line_items.map(line_item => {
                      const amount = quote[`${line_item.name}_${payment_mode}`];

                      if (amount) {
                        return (
                          <div key={line_item.name} style={styles.line_item}>
                            {line_item.label}
                            <span style={styles.amount}>
                              {line_item.is_estimate ? '~' : null}
                              {Numeral(amount).format('$0,0.00')}
                            </span>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                )}
                <div style={styles.total}>{Numeral(quote[`premium_${payment_mode}`]).format('$0,0.00')}</div>
              </div>
            ) : (
              <div style={styles.not_available}>This payment mode is not available.</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

QuotePremiums.propTypes = {
  quote: PropTypes.object
};

export default QuotePremiums;
