import PropTypes from 'prop-types';
import React from 'react';
import Numeral from 'numeral';

import Modal from 'shared/Modal';
import Table from 'shared/Table';

import { Colors, FontSizes, FontWeights } from 'constants/Clementine';

import AlqApi from 'utils/AlqApi';

class CompareQuotesModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    onShare: PropTypes.func,
    productFeatures: PropTypes.array,
    quotes: PropTypes.array,
    selectedQuotes: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      products: []
    };
  }

  componentDidMount() {
    AlqApi.loadProducts(this.props.quotes.map(q => q.product_id)).then(response => {
      this.setState({
        loading: false,
        products: response.data
      });
    });
  }

  render() {
    const { onClose, onShare, productFeatures, quotes, selectedQuotes } = this.props;
    const { products, loading } = this.state;
    const columns = [
      {
        id: 'product_name',
        is_sortable: true,
        default: true,
        label: 'Product',
        width: 200,
        render: item => {
          return (
            <div style={{ lineHeight: '1.2em' }}>
              <div style={{ fontSize: FontSizes.MEDIUM, fontWeight: FontWeights.MEDIUM, marginBottom: 5 }}>{item.product_name}</div>
              <div style={{ fontSize: FontSizes.SMALL, fontStyle: 'italic', color: '#999' }}>{item.carrier_name}</div>
            </div>
          );
        },
        sort_by: item => item.product_name.toLowerCase(),
        style: { justifyContent: 'flex-start' }
      },
      {
        id: 'ixn_health_category',
        is_sortable: true,
        label: 'Health Category',
        width: 200,
        render: item => (
          <div style={{ textAlign: 'center' }}>
            <div>{item.ixn_health_category}</div>
            <div style={{ fontSize: FontSizes.SMALL, fontStyle: 'italic', color: '#999' }}>({item.carrier_health_category})</div>
          </div>
        ),
        sort_by: item => item.ixn_health_category,
        style: { justifyContent: 'center' }
      },
      {
        id: 'face_amount',
        is_sortable: true,
        label: 'Face Amount',
        width: 150,
        render: item => Numeral(item.face_amount).format('$0,00'),
        style: { justifyContent: 'center' }
      },
      {
        id: 'premium_monthly',
        is_sortable: true,
        label: 'Monthly Premium',
        width: 150,
        render: item => Numeral(item.premium_monthly).format('$0,00.00'),
        style: { justifyContent: 'center' }
      },
      {
        id: 'premium_quarterly',
        is_sortable: true,
        label: 'Quarterly Premium',
        width: 150,
        render: item => Numeral(item.premium_quarterly).format('$0,00.00'),
        sort_by: item => item.premium_quarterly,
        style: { justifyContent: 'center' }
      },
      {
        id: 'premium_semi_annual',
        is_sortable: true,
        label: 'Semi-Annual Premium',
        width: 150,
        render: item => Numeral(item.premium_semi_annual).format('$0,00.00'),
        style: { justifyContent: 'center' }
      },
      {
        id: 'premium_annual',
        is_sortable: true,
        label: 'Annual Premium',
        width: 150,
        render: item => Numeral(item.premium_annual).format('$0,00.00'),
        style: { justifyContent: 'center' }
      },
      {
        id: 'am_best_rating',
        is_sortable: true,
        width: 100,
        label: 'A.M. Best Rating',
        style: { justifyContent: 'center' }
      }
    ];

    productFeatures.forEach(feature => {
      columns.push({
        id: feature.id,
        is_sortable: true,
        label: feature.name,
        width: 200,
        render: quote => {
          const quote_has_feature = quote.product.product_feature_ids.includes(feature.id);

          return quote_has_feature ? <i className='mdi mdi-check-bold' style={{ fontSize: 22, color: Colors.GREEN.hex }} /> : null;
        },
        style: { justifyContent: 'center' }
      });
    });

    const filtered_quotes = quotes.filter(q => {
      return selectedQuotes.includes(q.guid);
    });

    const prepped_quotes = filtered_quotes.map(q => {
      const product = products.find(p => p.id === q.product_id);

      q.product = product;

      return q;
    });

    return (
      <Modal
        buttons={[
          {
            onClick: onShare,
            children: 'Share',
            style: { marginLeft: 'auto' }
          }
        ]}
        loading={loading}
        maxWidth={1200}
        onClose={onClose}
        title='Comparing Quotes'
      >
        <Table columns={columns} data={prepped_quotes} />
      </Modal>
    );
  }
}

export default CompareQuotesModal;
