import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'shared/Modal';
import Button from 'shared/Button';

import { FontSizes, FontWeights } from 'constants/Clementine';

class OpenUrlModal extends React.Component {
  static propTypes = {
    inline: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired
  };

  componentDidMount() {
    if (!this.props.inline) {
      window.open(this.props.url);
    }
  }

  render() {
    const { inline, onClose, url } = this.props;
    const styles = this.styles();

    return (
      <Modal maxWidth={inline ? '100%' : 600} onClose={onClose}>
        {inline ? (
          <iframe src={url} style={styles.iframe} />
        ) : (
          <div style={styles.success_wrapper}>
            <div style={styles.success_title}>Success!</div>
            <div style={styles.success_description}>The link should have been opened in a new tab. If it wasn't click the button below.</div>
            <Button
              inline={true}
              onClick={() => {
                window.open(url);
              }}
            >
              Open Link
            </Button>
          </div>
        )}
      </Modal>
    );
  }

  styles = () => {
    return {
      iframe: {
        height: '100%',
        width: '100%',
        border: 'none'
      },
      success_wrapper: {
        textAlign: 'center',
        paddingTop: 40,
        height: 200,
        width: 600
      },
      success_title: {
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 20
      },
      success_description: {
        fontSize: FontSizes.MEDIUM,
        color: '#777',
        marginBottom: 40,
        lineHeight: '1.4em'
      }
    };
  };
}

export default OpenUrlModal;
