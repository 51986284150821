import { Colors, FontSizes, FontWeights } from 'constants/Clementine';

import Button from 'shared/Button';
import { CUSTOM_ACTIONS } from 'constants/App';
import Checkbox from 'shared/Checkbox';
import CompareQuotesModal from 'alq/CompareQuotesModal';
import Field from 'shared/Field';
import FieldGroup from 'shared/FieldGroup';
import Input from 'shared/Input';
import Numeral from 'numeral';
import { ProductContext } from 'alq/Context';
import PropTypes from 'prop-types';
import QuoteCard from 'alq/QuoteCard';
import RangePicker from 'shared/RangePicker';
import React from 'react';
import Select from 'shared/Select';
import ShareQuotesModal from 'alq/ShareQuotesModal';
import ZeroState from 'shared/ZeroState';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';
import { HealthCategories } from '../../constants/Clementine';

const group_by_def = {
  product_type: {
    quote_attr: 'product_type',
    label: 'Product Type',
    quote_data_attr: 'product_types',
    value: 'product_type'
  },
  face_amount: {
    quote_attr: 'face_amount',
    label: 'Face Amount',
    quote_data_attr: 'face_amounts',
    value: 'face_amount'
  },
  health_category: {
    quote_attr: 'ixn_health_category',
    label: 'Health Category',
    quote_data_attr: 'health_categories',
    value: 'health_category'
  }
};

class QuoteResults extends React.Component {
  static propTypes = {
    quotes: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      feature: {},
      group_by: 'product_type',
      instant_issue: false,
      modal: null,
      online_application: false,
      premium_mode: 'premium_monthly',
      premium_range: null,
      search: '',
      show_filters: false,
      sort_by: 'premium_monthly',
      sort_direction: 'asc',
      visible_group: null,
      is_read_only: true,
      selected_quotes: []
    };
  }

  _handleQuoteActionChange = name => {
    this.setState({ [name]: !this.state[name] });
  };

  _handleSearchChange = e => {
    this.setState({
      search: e.target.value,
      premium_range: null
    });
  };

  _handleSortDirectionChange = e => {
    this.setState({
      sort_direction: e.target.value
    });
  };

  _handleSortByChange = e => {
    this.setState({
      sort_by: e.target.value,
      premium_range: null,
      premium_mode: e.target.value.includes('premium_') ? e.target.value : 'premium_monthly'
    });
  };

  _handleGroupByChange = e => {
    this.setState({
      group_by: e.target.value,
      visible_group: null
    });
  };

  _handlePremiumChange = premium_range => {
    this.setState({
      premium_range
    });
  };

  _handleFeatureChange = e => {
    const feature = this.context.product_features.find(pf => pf.id === e.target.value) || {};

    this.setState({ feature });
  };

  _handleSelectQuote = quote => {
    let selected_quotes = this.state.selected_quotes.slice();

    if (selected_quotes.includes(quote.guid)) {
      selected_quotes = selected_quotes.filter(q_guid => q_guid !== quote.guid);
    } else {
      selected_quotes.push(quote.guid);
    }

    this.setState({
      selected_quotes
    });
  };

  _toggleFilters = () => {
    this.setState({
      show_filters: !this.state.show_filters
    });
  };

  _toggleModal = modal => {
    this.setState({
      modal
    });
  };

  _toggleVisibleGroup = visible_group => {
    this.setState({ visible_group });
  };

  _handleFocus = () => {
    this.setState({
      is_read_only: false
    });
  };

  _getCardsResult = (sorted_quotes, promoted_quotes, product_features, selected_quotes, styles) => {
    if (!sorted_quotes.length) {
      return <ZeroState icon='mdi-magnify' message='No matching products found. Try adjusting the criteria above.' style={styles.quoter_zero_state} />;
    }

    return (
      <>
        {promoted_quotes.length ? <div style={{ ...styles.selected_bar_count, ...styles.p20 }}>PROMOTED QUOTES</div> : null}
        {promoted_quotes.map(q => (
          <QuoteCard
            className='ixn-quote-card'
            customAction={q.custom_action}
            key={q.id}
            onSelect={this._handleSelectQuote}
            productFeatures={product_features}
            quote={q}
            selected={selected_quotes.includes(q.guid)}
          />
        ))}

        {promoted_quotes.length ? <div style={{ ...styles.selected_bar_count, ...styles.p20 }}>ALL QUOTES</div> : null}
        {sorted_quotes.map(q => (
          <QuoteCard
            className='ixn-quote-card'
            customAction={q.custom_action}
            key={q.id}
            onSelect={this._handleSelectQuote}
            productFeatures={product_features}
            quote={q}
            selected={selected_quotes.includes(q.guid)}
          />
        ))}
      </>
    );
  };

  render() {
    const { color, ran_quote_data, product_features } = this.context;
    const { quotes } = this.props;

    // munge quote actions onto the quote object
    const quotes_with_actions = quotes.map(quote => {
      const updated_quote = quote;
      let custom_action = null;
      const custom_actions = [];

      this.context.alq.custom_actions.map(a => {
        const carrier_ids = a.carrier_ids || [];
        const product_ids = a.product_ids || [];

        if (carrier_ids.includes(quote.carrier_id) && product_ids.includes(quote.product_id) && CUSTOM_ACTIONS.ETICKETS.includes(a.action_type)) {
          custom_action = a.action_type;
        }

        if (carrier_ids.includes(quote.carrier_id) && product_ids.includes(quote.product_id)) {
          return custom_actions.push(a);
        }

        return null;
      });
      return Object.assign(updated_quote, { custom_actions, custom_action });
    });

    const { feature, group_by, instant_issue, online_application, premium_mode, premium_range, search, visible_group, selected_quotes, show_filters, modal, sort_by, sort_direction } = this.state;
    const styles = this.styles();
    const search_filtered_quotes = quotes_with_actions.filter(q => {
      const search_string = search.toLowerCase();
      const matches_search = q.carrier_name.toLowerCase().includes(search_string) || q.product_name.toLowerCase().includes(search_string);

      return matches_search;
    });
    const filtered_quotes = search_filtered_quotes.filter(q => {
      let matches_application = true;
      let matches_feature = true;
      let matches_instant_issue = true;
      let matches_premium = true;

      if (premium_range) {
        matches_premium = q[premium_mode] >= premium_range[0] && q[premium_mode] <= premium_range[1];
      }

      if (feature.id) {
        matches_feature = feature.product_ids.includes(q.product_id);
      }

      if (instant_issue) {
        matches_instant_issue = q.custom_action && CUSTOM_ACTIONS.INSTANT_ISSUE.includes(q.custom_action);
      }

      if (online_application) {
        matches_application = q.custom_action && CUSTOM_ACTIONS.ETICKETS.includes(q.custom_action);
      }

      return matches_premium && matches_instant_issue && matches_feature && matches_application;
    });
    const premiums = search_filtered_quotes.map(q => {
      return q[premium_mode] ? parseInt(q[premium_mode], 10) : 0;
    });
    const lowest_premium = premiums.length ? Math.floor(Math.min(...premiums)) : 0;
    const highest_premium = premiums.length ? Math.ceil(Math.max(...premiums)) + 1 : 0;

    const group_def = group_by_def[group_by];
    const grouped_quotes = _groupBy(filtered_quotes, group_def.quote_attr);

    // use collator to sort the Product Types in correct numerical order
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    const groups = ran_quote_data[group_def.quote_data_attr].sort(collator.compare);
    // use the HealthCategories array in constants to sort the Health Category tabs in the same order
    const original_order = HealthCategories.all;
    groups.sort((a, b) => original_order.indexOf(a) - original_order.indexOf(b));

    const active_group = visible_group || groups[0];
    const visible_quotes = grouped_quotes[active_group];
    const promoted_quotes = !visible_quotes
      ? []
      : _sortBy(
          visible_quotes.filter(it => it.is_promoted),
          ['promoted_order']
        );
    const sorted_quotes = _orderBy(visible_quotes, [sort_by], [sort_direction]);

    return (
      <div style={styles.scrollContainer}>
        <div style={styles.container}>
          {show_filters ? <div onClick={this._toggleFilters} style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }} /> : null}

          {modal === 'share_modal' ? <ShareQuotesModal onClose={this._toggleModal.bind(null, null)} selectedQuotes={selected_quotes} /> : null}

          {modal === 'compare_modal' ? (
            <CompareQuotesModal
              onClose={this._toggleModal.bind(null, null)}
              onShare={this._toggleModal.bind(null, 'share_modal')}
              productFeatures={product_features}
              quotes={quotes_with_actions}
              selectedQuotes={selected_quotes}
            />
          ) : null}

          <div style={styles.header}>
            <div style={styles.filters_wrapper}>
              <div style={styles.pagination_total}>{filtered_quotes.length} Results</div>
              <div style={styles.search_wrapper}>
                <div onFocus={this._handleFocus} tabIndex={-1} style={{ outline: 'none' }}>
                  <Input onChange={this._handleSearchChange} placeholder='Search by carrier or product name...' readOnly={this.state.is_read_only} style={styles.search} value={search} />
                </div>
              </div>
              <i className={`mdi ${show_filters ? 'mdi-chevron-up' : 'mdi-tune-vertical'}`} id='ixn-tour-filters' onClick={this._toggleFilters} style={styles.filter_icon} />
              {show_filters ? (
                <div style={styles.filters}>
                  <div style={styles.filters_content}>
                    <FieldGroup label='Sort By'>
                      <Select
                        onChange={this._handleSortByChange}
                        options={[
                          { value: 'premium_monthly', label: 'Monthly Premium' },
                          {
                            value: 'premium_quarterly',
                            label: 'Quarterly Premium'
                          },
                          {
                            value: 'premium_semi_annual',
                            label: 'Semi-Annual Premium'
                          },
                          { value: 'premium_annual', label: 'Annual Premium' },
                          { value: 'face_amount', label: 'Face Amount' },
                          { value: 'carrier_name', label: 'Carrier Name' }
                        ]}
                        style={{ marginBottom: 10 }}
                        value={sort_by}
                      />
                      <Select
                        onChange={this._handleSortDirectionChange}
                        options={[
                          { value: 'asc', label: 'Ascending' },
                          { value: 'desc', label: 'Descending' }
                        ]}
                        value={sort_direction}
                      />
                    </FieldGroup>

                    <FieldGroup label='Group By' style={{ margin: '30px 0' }}>
                      <Select onChange={this._handleGroupByChange} options={Object.values(group_by_def)} value={group_by} />
                    </FieldGroup>

                    <FieldGroup label='Filter By'>
                      <Field label='Quote Actions'>
                        <Checkbox checked={online_application} label='Online Application' onChange={this._handleQuoteActionChange.bind(null, 'online_application')} />

                        {/* <Checkbox
                          checked={instant_issue}
                          label='Instant Issue'
                          onChange={this._handleQuoteActionChange.bind(null, 'instant_issue')}
                        /> */}
                      </Field>

                      <Field label={`Premium (${premium_mode.replace('premium_', '')})`}>
                        {search_filtered_quotes.length ? (
                          <RangePicker
                            color={color}
                            max={highest_premium}
                            min={lowest_premium}
                            onChange={this._handlePremiumChange}
                            rangeValues={premium_range || [lowest_premium, highest_premium]}
                            renderLabel={value => Numeral(value).format('$0,0')}
                            step={1}
                            values={premiums}
                          />
                        ) : null}
                      </Field>

                      <Field label='Product Features'>
                        <Select
                          onChange={this._handleFeatureChange}
                          options={[{ value: '', label: 'Any Feature' }].concat(
                            product_features.map(pf => ({
                              label: pf.name,
                              value: pf.id
                            }))
                          )}
                          value={feature.id}
                        />
                      </Field>
                    </FieldGroup>
                  </div>
                </div>
              ) : null}
            </div>

            {selected_quotes.length ? (
              <div style={styles.selected_bar}>
                <div style={styles.selected_bar_count}>{selected_quotes.length} Quotes Selected</div>
                <div style={{ display: 'flex' }}>
                  <Button icon='share-variant' onClick={this._toggleModal.bind(null, 'share_modal')} small={true} style={{ marginRight: 4 }}>
                    Share
                  </Button>
                  <Button icon='table' onClick={this._toggleModal.bind(null, 'compare_modal')} small={true}>
                    Compare
                  </Button>
                </div>
              </div>
            ) : null}
          </div>

          <div style={styles.tabs}>
            {groups.map(group => (
              <div key={group} onClick={this._toggleVisibleGroup.bind(null, group)} style={Object.assign({}, styles.tab, active_group === group && styles.tab_active)}>
                {group_by === 'face_amount' ? Numeral(group).format('$0,00') : group}
              </div>
            ))}
          </div>

          <div style={styles.results}>{this._getCardsResult(sorted_quotes, promoted_quotes, product_features, selected_quotes, styles)}</div>
        </div>
      </div>
    );
  }

  styles = () => {
    const { color } = this.context;

    return {
      scrollContainer: {
        overflowX: 'auto'
      },
      container: {
        width: '100%',
        minWidth: '900px',
        alignSelf: 'flex-start',
        alignSelf: 'auto',
        display: 'flex',
        flexDirection: 'column'
      },
      header: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
        marginBottom: 20
      },
      search_wrapper: {
        marginLeft: 'auto'
      },
      search: {
        width: 453
      },
      filters_wrapper: {
        display: 'flex',
        alignItems: 'center',
        background: '#f9f9f9',
        borderRadius: this.state.selected_quotes.length ? 0 : 4,
        padding: '10px 12px',
        border: '1px solid #e5e5e5',
        fontWeight: FontWeights.MEDIUM
      },
      filter_icon: {
        fontSize: 24,
        color,
        cursor: 'pointer',
        paddingLeft: 10
      },
      filters: {
        overflow: 'hidden',
        position: 'absolute',
        top: 61,
        width: 500,
        right: 0,
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 20px 0px'
      },
      filters_content: {
        padding: '20px 20px 0',
        background: '#fff',
        borderRadius: this.state.selected_quotes.length ? 0 : '0px 0px 4px 4px',
        border: '1px solid #e5e5e5'
      },
      selected_bar: {
        background: Colors.GRAY.hex,
        color: '#fff',
        padding: '10px 15px 10px 18px',
        fontWeight: FontWeights.MEDIUM,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '0px 0px 4px 4px'
      },
      results: {
        padding: 20,
        border: '1px solid #e5e5e5',
        borderRadius: '0px 4px 4px 4px',
        background: '#fff'
      },
      tabs: {
        display: 'flex',
        alignItems: 'flex-end'
      },
      tab: {
        borderTop: '1px solid #e5e5e5',
        borderRight: '1px solid #e5e5e5',
        borderBottom: '1px solid #e5e5e5',
        borderLeft: '1px solid #e5e5e5',
        background: '#f5f5f5',
        marginBottom: -1,
        position: 'relative',
        padding: '10px 14px',
        lineHeight: '1.3em',
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.MEDIUM,
        marginRight: 10,
        borderRadius: '4px 4px 0 0',
        cursor: 'pointer',
        position: 'relative'
      },
      tab_active: {
        padding: '12px 14px',
        background: '#fff',
        borderBottom: '1px solid #fff'
      },
      pagination_total: {
        fontWeight: FontWeights.MEDIUM,
        flexShrink: 0,
        marginRight: 15,
        marginLeft: 3
      },
      p20: {
        padding: 20
      }
    };
  };
}

QuoteResults.contextType = ProductContext;

export default QuoteResults;
