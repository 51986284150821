import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'shared/DatePicker';
import FieldGroup from 'shared/FieldGroup';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Select from 'shared/Select';

import { RELATIONSHIPS_CONDENSED, GENDERS } from 'constants/Eticket';

const Beneficiaries = ({ errors, formData, onInputChange, onDobChange }) => {
  const styles = {
    row: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    }
  };

  return (
    <React.Fragment>
      {[
        { label: 'Primary Beneficiary', prefix: 'primary_beneficiary_1', show: true, required: true },
        { label: 'Primary Beneficiary #2', prefix: 'primary_beneficiary_2', show: parseInt(formData.primary_beneficiary_1_percentage, 10) < 100, required: true },
        { label: 'Contingent Beneficiary', prefix: 'contingent_beneficiary_1', show: true, required: false },
        { label: 'Contingent Beneficiary #2', prefix: 'contingent_beneficiary_2', show: parseInt(formData.contingent_beneficiary_1_percentage, 10) < 100, required: true }
      ].map(section => {
        return section.show ? (
          <FieldGroup label={section.label}>
            <Field label='Distribution'>
              <Select
                invalid={errors.includes(`${section.prefix}_distribution`)}
                onChange={onInputChange.bind(null, `${section.prefix}_distribution`, null)}
                options={[
                  { value: '', label: '-- Select One --' },
                  { value: 'survivors_equally', label: 'Equally to the survivors' },
                  { value: 'stirpes_equally', label: 'Equally, per stirpes' },
                  { value: 'survivors_percentage', label: 'By a defined percentage to the survivors' },
                  { value: 'stirpes_percentage', label: 'By a defined percentage, per stirpes' }
                ]}
                required={section.required}
                value={formData[`${section.prefix}_distribution`]}
              />
            </Field>

            <Field label='Relationship To Insured'>
              <Select
                invalid={errors.includes(`${section.prefix}_relationship`)}
                onChange={onInputChange.bind(null, `${section.prefix}_relationship`, null)}
                options={[{ value: '', label: '-- Select One --' }].concat(RELATIONSHIPS_CONDENSED)}
                required={section.required}
                value={formData[`${section.prefix}_relationship`]}
              />
            </Field>

            <div style={styles.row}>
              <Field label='First Name' style={{ width: 'calc(50% - 50px)' }}>
                <Input
                  invalid={errors.includes(`${section.prefix}_first_name`)}
                  maxLength={20}
                  onChange={onInputChange.bind(null, `${section.prefix}_first_name`, null)}
                  required={section.required}
                  value={formData[`${section.prefix}_first_name`]}
                />
              </Field>
              <Field label='Middle Initial' style={{ width: 80, margin: '0 10px' }}>
                <Input maxLength={10} onChange={onInputChange.bind(null, `${section.prefix}_middle_initial`, null)} value={formData[`${section.prefix}_middle_initial`]} />
              </Field>
              <Field label='Last Name' style={{ width: 'calc(50% - 50px)' }}>
                <Input
                  invalid={errors.includes(`${section.prefix}_last_name`)}
                  maxLength={40}
                  onChange={onInputChange.bind(null, `${section.prefix}_last_name`, null)}
                  required={section.required}
                  value={formData[`${section.prefix}_last_name`]}
                />
              </Field>
            </div>

            <div style={styles.row}>
              <Field label='Gender' style={{ width: 'calc(25% - 5px)' }}>
                <Select
                  invalid={errors.includes(`${section.prefix}_gender`)}
                  onChange={onInputChange.bind(null, `${section.prefix}_gender`, null)}
                  options={[{ value: '', label: '-- Select One --' }].concat(GENDERS)}
                  required={section.required}
                  value={formData[`${section.prefix}_gender`]}
                />
              </Field>

              <Field label='Date of Birth' style={{ width: 'calc(75% - 5px)' }}>
                <DatePicker
                  invalid={errors.includes(`${section.prefix}_date_of_birth`)}
                  onChange={onDobChange.bind(null, `${section.prefix}_date_of_birth`, null)}
                  required={section.required}
                  value={formData[`${section.prefix}_date_of_birth`]}
                />
              </Field>
            </div>

            <Field label='Percentage'>
              <Input
                invalid={errors.includes(`${section.prefix}_percentage`)}
                onChange={onInputChange.bind(null, `${section.prefix}_percentage`, null)}
                required={section.required}
                type='number'
                value={formData[`${section.prefix}_percentage`]}
              />
            </Field>
          </FieldGroup>
        ) : null;
      })}
    </React.Fragment>
  );
};

Beneficiaries.propTypes = {
  errors: PropTypes.array,
  formData: PropTypes.object,
  onDobChange: PropTypes.func,
  onInputChange: PropTypes.func,
  quote: PropTypes.object
};

export default Beneficiaries;
