import PropTypes from 'prop-types';
import React from 'react';
import Numeral from 'numeral';

import { ProductContext } from 'alq/Context';

import Modal from 'shared/Modal';
import Input from 'shared/Input';
import FieldGroup from 'shared/FieldGroup';
import Field from 'shared/Field';
import Select from 'shared/Select';

import { Colors, FontSizes, FontWeights } from 'constants/Clementine';

import AlqApi from 'utils/AlqApi';

const COLLEGE_TYPE_OPTIONS = [
  { label: 'None', value: '' },
  { label: 'Public 2-Year', value: 'public_two_year' },
  { label: 'Public 4-Year (in-state)', value: 'public_four_year_in_state' },
  { label: 'Public 4-Year (out-of-state)', value: 'public_four_year_out_of_state' },
  { label: 'Private 4-Year', value: 'private_four_year' }
];

class NeedsAnalysisModal extends React.Component {
  static propTypes = {
    agent_id: PropTypes.number,
    maximum_face_amount: PropTypes.number,
    minimum_face_amount: PropTypes.number,
    onBackToTools: PropTypes.func,
    onClose: PropTypes.func,
    onQuoteWithCoverage: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      mortgage: '0',
      other_debts: '15000',
      final_expenses: '10000',
      children: [],
      child_care_average_cost: '0',
      health_care_cost: '0',
      income: '0',
      income_replacement_percentage: '75',
      years_of_income_replacement: '10',
      existing_savings: '0',
      existing_insurance: '0',
      inflation_rate: '2.0',
      investment_return_rate: '4.0',
      criteria_errors: [],
      calculated_coverage: null,
      showNotInRangeMessage: false
    };
  }

  _handleInputChange = (name, e) => {
    this.setState(
      {
        [name]: e.target.value
      },
      () => this._validateCriteria()
    );
  };

  _handleChildCountChange = e => {
    const new_count = parseInt(e.target.value, 10);

    this.setState(
      prev_state => {
        if (new_count < prev_state.children.length) {
          return { children: prev_state.children.slice(0, new_count) };
        } else if (new_count > prev_state.children.length) {
          const new_children = new Array(new_count).fill({});

          prev_state.children.forEach((child, index) => {
            new_children[index] = child;
          });

          return { children: new_children };
        } else {
          return prev_state;
        }
      },
      () => this._validateCriteria()
    );
  };

  _handleChildInputChange = (child_index, name, e) => {
    const { value } = e.target;

    this.setState(
      prev_state => {
        return {
          children: prev_state.children.map((child, index) => {
            if (index === child_index) {
              return value ? { ...child, [name]: value } : { age: child.age };
            } else {
              return child;
            }
          })
        };
      },
      () => this._validateCriteria()
    );
  };

  _validateCriteria = () => {
    const criteria_errors = [];

    if (this.state.children.length > 0) {
      this.state.children.forEach((child, index) => {
        if (!child.age) {
          criteria_errors.push(`child_${index}`);
        }
      });
    }

    if (!this.state.income_replacement_percentage) {
      criteria_errors.push('income_replacement_percentage');
    }

    if (!this.state.years_of_income_replacement) {
      criteria_errors.push('years_of_income_replacement');
    }

    if (!this.state.inflation_rate) {
      criteria_errors.push('inflation_rate');
    }

    if (!this.state.investment_return_rate) {
      criteria_errors.push('investment_return_rate');
    }

    this.setState({
      criteria_errors
    });

    return !criteria_errors.length;
  };

  _calculateCoverage = () => {
    if (this._validateCriteria()) {
      AlqApi.calculateNeeds({
        mortgage: parseInt(this.state.mortgage, 10),
        other_debts: parseInt(this.state.other_debts, 10),
        final_expenses: parseInt(this.state.final_expenses, 10),
        children_records: this.state.children,
        child_care_average_cost: parseInt(this.state.child_care_average_cost, 10),
        health_care_cost: parseInt(this.state.health_care_cost, 10),
        income: parseInt(this.state.income, 10),
        income_replacement_percentage: parseFloat(this.state.income_replacement_percentage),
        years_of_income_replacement: parseInt(this.state.years_of_income_replacement, 10),
        existing_savings: parseInt(this.state.existing_savings, 10),
        existing_insurance: parseInt(this.state.existing_insurance, 10),
        inflation_rate: parseFloat(this.state.inflation_rate),
        investment_return_rate: parseFloat(this.state.investment_return_rate),
        member_id: this.props.agent_id
      }).then(response => {
        if (response.data.coverage < this.props.minimum_face_amount || response.data.coverage > this.props.maximum_face_amount) {
          this.setState({
            calculated_coverage: response.data.coverage,
            needs_analysis_disclaimer: response.data.disclaimer,
            showNotInRangeMessage: true
          });
        } else {
          this.setState({
            calculated_coverage: response.data.coverage,
            needs_analysis_disclaimer: response.data.disclaimer,
            showNotInRangeMessage: false
          });
        }
      });
    }
  };

  _backToNeedsCriteria = () => {
    this.setState({ calculated_coverage: null });
  };

  render() {
    const styles = this.styles();
    const { criteria_errors } = this.state;

    if (this.state.calculated_coverage) {
      return (
        <Modal
          buttons={[
            {
              color: '#e5e5e5',
              fontColor: '#444',
              children: 'Back To Needs Analysis',
              onClick: this._backToNeedsCriteria,
              style: { marginRight: 'auto' }
            },
            {
              children: 'Quote With This Coverage',
              disabled: this.state.calculated_coverage <= 0 || this.state.showNotInRangeMessage,
              onClick: this.props.onQuoteWithCoverage.bind(null, parseInt(this.state.calculated_coverage, 10)),
              style: { marginLeft: 10 }
            }
          ]}
          maxWidth={800}
          onClose={this.props.onClose}
          title='Needs Analysis Calculator'
        >
          {this.state.showNotInRangeMessage ? (
            <>
              <div style={styles.calculation_result}>
                <div style={styles.calculation_result_title}>Estimated Coverage Need Out of Range</div>
                <div style={styles.calculation_result_coverage}>{Numeral(this.state.calculated_coverage <= 0 ? 0 : this.state.calculated_coverage).format('$0,0')}</div>
                <div style={styles.calculation_result_disclaimer}>
                  Enter a face amounts between {Numeral(this.props.minimum_face_amount).format('$0,0')} and {Numeral(this.props.maximum_face_amount).format('$0,0')}...` : 'Only 3 Face Amounts
                  Allowed.'
                </div>
                <br />
                <div style={styles.calculation_result_disclaimer}>{this.state.needs_analysis_disclaimer}</div>
              </div>
            </>
          ) : (
            <>
              <div style={styles.calculation_result}>
                <div style={styles.calculation_result_title}>Estimated Coverage Need</div>
                <div style={styles.calculation_result_coverage}>{Numeral(this.state.calculated_coverage <= 0 ? 0 : this.state.calculated_coverage).format('$0,0')}</div>
                {this.state.calculated_coverage <= 0 && (
                  <>
                    <div style={styles.calculation_result_disclaimer}>Based on your inputs, current assets and/or insurance policies will cover all estimated future needs.</div>
                    <br />
                  </>
                )}
                <div style={styles.calculation_result_disclaimer}>{this.state.needs_analysis_disclaimer}</div>
              </div>
            </>
          )}
        </Modal>
      );
    } else {
      return (
        <Modal
          buttons={[
            {
              color: '#e5e5e5',
              fontColor: '#444',
              children: 'Back To Tools',
              onClick: this.props.onBackToTools,
              style: { marginRight: 'auto' }
            },
            {
              children: 'Calculate Needs',
              disabled: criteria_errors.length > 0,
              onClick: this._calculateCoverage,
              style: { marginLeft: 10 }
            }
          ]}
          maxWidth={800}
          onClose={this.props.onClose}
          title='Needs Analysis Calculator'
        >
          <div style={styles.criteria_content}>
            <div style={styles.criteria_column}>
              <FieldGroup label='Debts and Expenses'>
                <Field label='Outstanding Mortgage'>
                  <Input format='currency' onChange={this._handleInputChange.bind(null, 'mortgage')} value={this.state.mortgage} />
                </Field>
                <Field label='Other Debts' tooltip={<span>Non-mortgage debt: credit cards, auto loans, student loans, etc.</span>}>
                  <Input format='currency' onChange={this._handleInputChange.bind(null, 'other_debts')} value={this.state.other_debts} />
                </Field>
                <Field
                  label='Final Expenses'
                  tooltip={
                    <span>
                      The median cost of a funeral and burial is $7,640 according to the National Funeral Directors Association. There may also be medical and estate settlement expenses to cover.
                    </span>
                  }
                >
                  <Input format='currency' onChange={this._handleInputChange.bind(null, 'final_expenses')} value={this.state.final_expenses} />
                </Field>
              </FieldGroup>

              <FieldGroup label='Children' style={{ marginTop: 20 }}>
                <Field label='Number of Children' style={{ marginTop: 20 }}>
                  <Select
                    onChange={this._handleChildCountChange}
                    options={[{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }]}
                    value={this.state.children.length}
                  />
                </Field>
                <div style={styles.child_age_column}>
                  {this.state.children.map((child, index) => {
                    return (
                      <div key={index} style={{ display: 'flex', marginLeft: 20 }}>
                        <div style={{ width: 100, paddingRight: 20 }}>
                          <Field
                            action={{
                              label: criteria_errors.includes(`child_${index}`) ? (
                                <div style={styles.error_msg}>
                                  <i className='mdi mdi-alert' style={styles.error_icon} />
                                </div>
                              ) : null
                            }}
                            label='Child Age'
                          >
                            <Input onChange={this._handleChildInputChange.bind(null, index, 'age')} step='1' type='number' value={child.age} />
                          </Field>
                        </div>
                        <div style={{ flex: 1 }}>
                          <Field label='College Plans'>
                            <Select onChange={this._handleChildInputChange.bind(null, index, 'college_type')} options={COLLEGE_TYPE_OPTIONS} value={child.college_type} />
                          </Field>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {this.state.children.length > 0 && (
                  <Field label='Annual Child Care (Per Child)' tooltip={<span>The annual cost (per child) of replacing child care services currently provided by the insured.</span>}>
                    <Input format='currency' onChange={this._handleInputChange.bind(null, 'child_care_average_cost')} value={this.state.child_care_average_cost} />
                  </Field>
                )}
              </FieldGroup>
            </div>

            <div style={styles.criteria_column}>
              <FieldGroup label='Income Replacement'>
                <Field label='Annual Income' tooltip={<span>The annual income (before tax) earned by the insured.</span>}>
                  <Input format='currency' onChange={this._handleInputChange.bind(null, 'income')} value={this.state.income} />
                </Field>
                <Field
                  action={{
                    label: criteria_errors.includes('income_replacement_percentage') ? (
                      <div style={styles.error_msg}>
                        Required <i className='mdi mdi-alert' style={styles.error_icon} />
                      </div>
                    ) : null
                  }}
                  label='Percentage of Income to Replace'
                  tooltip={
                    <span>
                      What percentage of the insured's income is needed by the beneficiary or beneficiaries to maintain their current standard of living? 70 to 90 percent is recommended, depending on
                      income level and personal expenses.
                    </span>
                  }
                >
                  <Input maxLength={100} onChange={this._handleInputChange.bind(null, 'income_replacement_percentage')} type='number' value={this.state.income_replacement_percentage} />
                </Field>
                <Field label='Years of Income Replacement Needed'>
                  <Input onChange={this._handleInputChange.bind(null, 'years_of_income_replacement')} type='number' value={this.state.years_of_income_replacement} />
                </Field>
                <Field label='Annual Health Care' tooltip={<span>The annual cost of replacing the health care plan currently provided by the insured's employer</span>}>
                  <Input format='currency' onChange={this._handleInputChange.bind(null, 'health_care_cost')} value={this.state.health_care_cost} />
                </Field>
              </FieldGroup>
            </div>

            <div style={styles.criteria_column}>
              <FieldGroup label='Assets'>
                <Field label='Current Savings & Investments'>
                  <Input format='currency' onChange={this._handleInputChange.bind(null, 'existing_savings')} value={this.state.existing_savings} />
                </Field>
                <Field label='Existing Life Insurance'>
                  <Input format='currency' onChange={this._handleInputChange.bind(null, 'existing_insurance')} value={this.state.existing_insurance} />
                </Field>
              </FieldGroup>

              <FieldGroup label='More Options' style={{ marginTop: 20 }}>
                <Field
                  label='Inflation Rate'
                  tooltip={<span>Inflation will increase income needs over time. Based on historical averages, 2.0% is recommended as an estimated rate of annual inflation.</span>}
                  tooltipPosition='left'
                >
                  <Input onChange={this._handleInputChange.bind(null, 'inflation_rate')} type='number' value={this.state.inflation_rate} />
                </Field>
                <Field label='Rate of Return' tooltip={<span>The annual rate of return on investing death benefit proceeds.</span>} tooltipPosition='left'>
                  <Input onChange={this._handleInputChange.bind(null, 'investment_return_rate')} type='number' value={this.state.investment_return_rate} />
                </Field>
              </FieldGroup>
            </div>
          </div>
        </Modal>
      );
    }
  }

  styles = () => {
    return {
      calculation_result: {
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      calculation_result_title: {
        fontSize: FontSizes.LARGE,
        fontWeight: FontWeights.MEDIUM
      },
      calculation_result_coverage: {
        fontSize: 40,
        fontWeight: 600,
        marginTop: 10,
        marginBottom: 40
      },
      calculation_result_disclaimer: {
        width: 400,
        fontStyle: 'italic',
        fontSize: FontSizes.REGULAR,
        lineHeight: '1.6em'
      },
      criteria_content: {
        padding: 30,
        display: 'flex',
        justifyContent: 'space-between'
      },
      criteria_column: {
        width: 'calc(33.3% - 20px)'
      },
      error_msg: {
        fontSize: FontSizes.SMALL,
        color: Colors.RED.hex,
        position: 'relative',
        paddingLeft: 18,
        fontWeight: FontWeights.MEDIUM
      },
      error_icon: {
        fontSize: 16,
        position: 'absolute',
        left: -1,
        top: -2
      }
    };
  };
}

NeedsAnalysisModal.contextType = ProductContext;

export default NeedsAnalysisModal;
