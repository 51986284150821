import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import _mergeWith from 'lodash/mergeWith';

import { ProductContext } from 'alq/Context';

import Modal from 'shared/Modal';
import QuoteSimple from 'shared/QuoteSimple';

import AlqApi from 'utils/AlqApi';
import FormUtils from 'utils/Form';

import ProposedInsured from './ProposedInsured';
import Beneficiaries from './Beneficiaries';
import Billing from './Billing';
import Progress from './Progress';

import Page from 'shared/Page';

import { SA_BASE_URL } from 'config/App';

class Index extends React.Component {
  static propTypes = {
    eticketGuid: PropTypes.string,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    quote: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    const { date_of_birth, current_age } = context.ran_quote_data;
    const quote_params = context.quote_params || [];
    const prefilled_data = {};

    quote_params.forEach(item => {
      if (!['date_of_birth', 'age'].includes(item.name)) {
        prefilled_data[item.name] = item.value ? `${item.value}` : null;
      }
    });

    this.state = {
      page_number: 1,
      show_quote: false,
      errors: [],
      hard_stop: false,
      hard_stop_attribute: null,
      hard_stop_reason: '',
      loading: true,
      provider_status: 'new',

      // Using _mergeWith vs Object.assign allows us to skip null values
      form_data: _mergeWith(
        {},
        {
          intent_to_replace: 'no',

          // Proposed Insured
          first_name: context.client.first_name || '',
          middle_initial: '',
          last_name: context.client.last_name || '',
          address: '',
          city: '',
          zip: '',
          phone_primary: '',
          phone_secondary: '',
          email: '',
          dob_year: date_of_birth ? Moment(date_of_birth).format('YYYY') : Moment().subtract(current_age, 'years').format('YYYY'),
          dob_month: date_of_birth ? Moment(date_of_birth).format('MM') : '',
          dob_day: date_of_birth ? Moment(date_of_birth).format('DD') : '',
          age: date_of_birth ? Moment().diff(Moment(date_of_birth), 'years') : current_age,
          country_of_birth: '',
          state_of_birth: '',
          ssn: '',
          citizenship_proof: 'state_dl',
          citizenship_dl_number: '',
          citizenship_dl_state: '',
          citizenship_dl_expiration_date: '',
          citizenship_id_number: '',
          citizenship_id_state: '',
          citizenship_id_expiration_date: '',
          citizenship_passport_number: '',
          citizenship_passport_country: '',
          citizenship_passport_expiration_date: '',
          citizenship_permanent_resident: false,
          citizenship_country_of_origin: '',
          citizenship_date_of_entry: '',
          citizenship_current_country: '',
          citizenship_visa_number: '',
          citizenship_visa_expiration_date: '',

          // Beneficiaries
          primary_beneficiary_1_distribution: 'survivors_percentage',
          primary_beneficiary_1_first_name: '',
          primary_beneficiary_1_middle_initial: '',
          primary_beneficiary_1_last_name: '',
          primary_beneficiary_1_gender: '',
          primary_beneficiary_1_date_of_birth: '',
          primary_beneficiary_1_relationship: 'spouse',
          primary_beneficiary_1_percentage: '100',
          primary_beneficiary_2_distribution: 'survivors_percentage',
          primary_beneficiary_2_first_name: '',
          primary_beneficiary_2_middle_initial: '',
          primary_beneficiary_2_last_name: '',
          primary_beneficiary_2_gender: '',
          primary_beneficiary_2_date_of_birth: '',
          primary_beneficiary_2_relationship: '',
          primary_beneficiary_2_percentage: '',
          contingent_beneficiary_1_distribution: 'survivors_percentage',
          contingent_beneficiary_1_first_name: '',
          contingent_beneficiary_1_middle_initial: '',
          contingent_beneficiary_1_last_name: '',
          contingent_beneficiary_1_gender: '',
          contingent_beneficiary_1_date_of_birth: '',
          contingent_beneficiary_1_relationship: '',
          contingent_beneficiary_1_percentage: '100',
          contingent_beneficiary_2_distribution: 'survivors_percentage',
          contingent_beneficiary_2_first_name: '',
          contingent_beneficiary_2_middle_initial: '',
          contingent_beneficiary_2_last_name: '',
          contingent_beneficiary_2_gender: '',
          contingent_beneficiary_2_date_of_birth: '',
          contingent_beneficiary_2_relationship: '',
          contingent_beneficiary_2_percentage: '',

          // Billing
          billing_frequency: '',
          billing_method: '',
          automatic_premium_mode: '',
          dividend_option: '',

          referrer: '' // How did you hear about us
        },
        prefilled_data,
        (a, b) => {
          return b === null ? a : undefined;
        }
      )
    };

    this.contentRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.eticketGuid) {
      AlqApi.loadEticket(this.props.eticketGuid).then(response => {
        const form_fields = JSON.parse(response.data.form_fields || '[]');
        const form_data = {};

        form_fields.forEach(field => {
          form_data[field.label] = field.value || '';
        });

        this.setState({
          provider_status: response.data.provider_status,
          form_data: Object.assign({}, this.state.form_data, form_data),
          loading: false
        });
      });
    } else {
      const params = this._getRequestParams();

      params.agency_id = this.context.alq.agency_id;
      params.quote_guid = this.props.quote.guid;
      params.source_type = 'BgaQuoter';
      params.source_guid = window.IXN_QUOTER_CONFIG.id;
      params.provider = 'nmb_insureio';
      params.provider_status = 'new';

      AlqApi.createEticket(params)
        .then(response => {
          this.props.onCreate(response.data.guid);
          this.setState({
            loading: false
          });
        })
        .catch(() => {
          //TODO: Handle error
        });
    }
  }

  _getRequestParams = () => {
    const { city, email, first_name, last_name, middle_initial, phone_primary, address, zip, dob_year, dob_month, dob_day } = this.state.form_data;

    return {
      city,
      email,
      first_name,
      last_name,
      middle_name: middle_initial,
      phone: phone_primary,
      street1: address,
      zip,
      state: this.props.quote.state,
      agent_email: this.context.agent.email,
      form_fields: JSON.stringify(
        Object.assign(
          {
            date_of_birth: `${dob_year}-${dob_month}-${dob_day}`
          },
          this.state.form_data
        )
      )
    };
  };

  _handleInputChange = (name, additionalCheck, e) => {
    const { form_data } = this.state;

    this.setState(
      {
        form_data: Object.assign({}, form_data, { [name]: e.target.value })
      },
      () => {
        if (additionalCheck) {
          additionalCheck(this);
        }
      }
    );
  };

  _handleDobChange = (name, additionalCheck, date_of_birth) => {
    this.setState(
      {
        form_data: Object.assign({}, this.state.form_data, { [name]: date_of_birth })
      },
      () => {
        if (additionalCheck) {
          additionalCheck(this);
        }
      }
    );
  };

  _resetHardStop = () => {
    const name = this.state.hard_stop_attribute;

    this.setState({
      hard_stop: false,
      hard_stop_attribute: null,
      hard_stop_reason: '',
      form_data: Object.assign({}, this.state.form_data, { [name]: name === 'citizenship_proof' ? 'state_dl' : 'no' })
    });
  };

  _toggleQuote = () => {
    this.setState({
      show_quote: !this.state.show_quote
    });
  };

  _checkRequired = (required = []) => {
    const errors = [];
    const { form_data } = this.state;

    required.forEach(name => {
      if (!form_data[name].length) {
        errors.push(name);
      }
    });

    return errors;
  };

  _back = () => {
    this.contentRef.current.scrollTop = 0;

    this.setState({
      page_number: this.state.page_number - 1
    });
  };

  _next = validate => {
    const errors = validate();

    this.contentRef.current.scrollTop = 0;

    if (errors.length) {
      this.setState({ errors });
    } else {
      this.setState({
        page_number: this.state.page_number + 1,
        errors: []
      });
    }
  };

  _submitEticket = validate => {
    const errors = validate();

    if (errors.length) {
      this.setState({ errors });
    } else if (!this.state.hard_stop) {
      this.setState({
        loading: true
      });

      const params = this._getRequestParams();

      params.provider_status = 'queued';

      AlqApi.updateEticket(this.props.eticketGuid, params)
        .then(response => {
          this.setState({
            page_number: 5,
            provider_status: response.data.provider_status,
            loading: false
          });
        })
        .catch(() => {
          this.setState({
            page_number: 5,
            provider_status: 'error',
            loading: false
          });
        });
    }
  };

  render() {
    const { form_data, errors, provider_status, page_number, loading, hard_stop, show_quote, hard_stop_reason } = this.state;
    const { onClose, quote, eticketGuid } = this.props;
    const styles = this.styles();
    const page_details = {
      1: {
        title: 'Proposed Insured',
        buttons: [
          {
            children: 'Next',
            onClick: this._next.bind(null, () => {
              const errors = [];
              const required = ['first_name', 'last_name', 'address', 'city', 'zip', 'phone_primary', 'dob_year', 'dob_month', 'dob_day', 'country_of_birth', 'state_of_birth', 'citizenship_proof'];

              if (!form_data.ssn || form_data.ssn.length < 9) {
                errors.push('ssn');
              }

              if (form_data.phone_primary.length && form_data.phone_primary.length !== 10) {
                errors.push('phone_primary');
              }

              if (form_data.phone_secondary.length && form_data.phone_secondary.length !== 10) {
                errors.push('phone_secondary');
              }

              if (form_data.citizenship_proof === 'state_dl') {
                required.push('citizenship_dl_number', 'citizenship_dl_state', 'citizenship_dl_expiration_date');
              }

              if (form_data.citizenship_proof === 'state_id') {
                required.push('citizenship_id_number', 'citizenship_id_state', 'citizenship_id_expiration_date');
              }

              if (form_data.citizenship_proof === 'passport') {
                required.push('citizenship_passport_number', 'citizenship_passport_country', 'citizenship_passport_expiration_date');
              }

              if (form_data.citizenship_proof === 'permanent_resident_card') {
                required.push('citizenship_country_of_origin', 'citizenship_current_country', 'citizenship_date_of_entry', 'citizenship_visa_number', 'citizenship_visa_expiration_date');
              }

              if (form_data.email.length && !FormUtils._validateEmail(form_data.email)) {
                errors.push('email');
              }

              return errors.concat(this._checkRequired(required));
            }),
            style: { margin: '0 10px 0 auto' }
          }
        ],
        content: <ProposedInsured errors={errors} formData={form_data} onInputChange={this._handleInputChange} quote={quote} />
      },
      2: {
        title: 'Beneficiaries',
        buttons: [
          {
            children: 'Back',
            onClick: this._back,
            color: '#e5e5e5',
            fontColor: '#999',
            style: { margin: '0 10px 0 auto' }
          },
          {
            children: 'Next',
            onClick: this._next.bind(null, () => {
              const errors = [];
              const required = [
                'primary_beneficiary_1_distribution',
                'primary_beneficiary_1_first_name',
                'primary_beneficiary_1_last_name',
                'primary_beneficiary_1_gender',
                'primary_beneficiary_1_date_of_birth',
                'primary_beneficiary_1_percentage',
                'primary_beneficiary_1_relationship',
                'primary_beneficiary_1_percentage'
              ];

              if (parseInt(form_data.primary_beneficiary_1_percentage, 10) > 100) {
                errors.push('primary_beneficiary_1_percentage');
              }

              if (parseInt(form_data.primary_beneficiary_1_percentage, 10) < 100) {
                required.push(
                  'primary_beneficiary_2_distribution',
                  'primary_beneficiary_2_first_name',
                  'primary_beneficiary_2_last_name',
                  'primary_beneficiary_2_gender',
                  'primary_beneficiary_2_date_of_birth',
                  'primary_beneficiary_2_percentage',
                  'primary_beneficiary_2_relationship',
                  'primary_beneficiary_2_percentage'
                );
              }

              if (parseInt(form_data.primary_beneficiary_2_percentage, 10) > 100) {
                errors.push('primary_beneficiary_2_percentage');
              }

              if (parseInt(form_data.contingent_beneficiary_1_percentage, 10) > 100) {
                errors.push('contingent_beneficiary_1_percentage');
              }

              if (parseInt(form_data.contingent_beneficiary_1_percentage, 10) < 100) {
                required.push(
                  'contingent_beneficiary_2_distribution',
                  'contingent_beneficiary_2_first_name',
                  'contingent_beneficiary_2_last_name',
                  'contingent_beneficiary_2_gender',
                  'contingent_beneficiary_2_date_of_birth',
                  'contingent_beneficiary_2_percentage',
                  'contingent_beneficiary_2_relationship',
                  'contingent_beneficiary_2_percentage'
                );
              }

              if (parseInt(form_data.contingent_beneficiary_2_percentage, 10) > 100) {
                errors.push('contingent_beneficiary_2_percentage');
              }

              return errors.concat(this._checkRequired(required));
            })
          }
        ],
        content: <Beneficiaries errors={errors} formData={form_data} onDobChange={this._handleDobChange} onInputChange={this._handleInputChange} />
      },
      3: {
        title: 'Billing',
        buttons: [
          {
            children: 'Back',
            onClick: this._back,
            color: '#e5e5e5',
            fontColor: '#999',
            style: { margin: '0 10px 0 auto' }
          },
          {
            children: 'Submit',
            onClick: this._submitEticket.bind(null, () => {
              const required = ['billing_method', 'billing_frequency'];

              return this._checkRequired(required);
            })
          }
        ],
        content: <Billing errors={errors} formData={form_data} onInputChange={this._handleInputChange} quote={quote} />
      }
    };
    const page = page_details[page_number];
    const show_form = ['new', 'queued'].includes(provider_status);
    const buttons = show_form ? page.buttons : [{ children: 'Done', onClick: this.props.onClose, style: { marginLeft: 'auto' } }];

    return (
      <Modal
        buttons={
          hard_stop
            ? null
            : [
                {
                  children: show_quote ? 'Hide Quote' : 'View Quote',
                  onClick: this._toggleQuote,
                  outline: true
                }
              ].concat(buttons)
        }
        loading={loading}
        maxWidth={800}
        onClose={onClose}
        title='BetterLife E-Ticket'
      >
        <div ref={this.contentRef}>
          {hard_stop ? (
            <Page button={{ children: 'Edit Info', onClick: this._resetHardStop }} style={styles.hard_stop} title='Cannot Continue'>
              {hard_stop_reason}
            </Page>
          ) : null}

          {provider_status === 'new' ? (
            <React.Fragment>
              <Progress currentStep={page_number} title={page.title} totalSteps={Object.keys(page_details).length} />

              <div style={{ padding: 30 }}>{page.content}</div>

              <QuoteSimple quote={quote} style={styles.quote} />
            </React.Fragment>
          ) : null}

          {provider_status === 'completed' ? (
            <Page imageUrl={`${SA_BASE_URL}/ixn/manifests/Success-Image.jpg`} title="It's on the way!">
              Thank you for doing business with us! Your electronic application has been submitted. The application request id is:
              <br />
              <br />"{eticketGuid}""
            </Page>
          ) : null}

          {provider_status === 'error' ? (
            <Page title='Error'>
              An error occurred while submitting the information. Please try again or contact support with the following application request id:
              <br />
              <br />"{eticketGuid}".
            </Page>
          ) : null}
        </div>
      </Modal>
    );
  }

  styles = () => {
    return {
      quote: {
        position: 'fixed',
        bottom: this.state.show_quote ? 134 : null,
        right: 20,
        left: 20,
        borderRadius: '4px',
        transform: this.state.show_quote ? 'translateY(0%)' : 'translateY(100%)',
        transition: 'all .2s',
        zIndex: 1
      },
      hard_stop: {
        position: 'fixed',
        top: 71,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        zIndex: 101,
        background: '#fff'
      }
    };
  };
}

Index.contextType = ProductContext;

export default Index;
