import React from 'react';
import Cookies from 'js-cookie';
import Numeral from 'numeral';
import PropTypes from 'prop-types';
import Button from 'shared/Button';

import { ProductContext } from 'alq/Context';

import Checkbox from 'shared/Checkbox';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Modal from 'shared/Modal';
import Select from 'shared/Select';

import { SA_BASE_URL } from 'config/App';

import { PAYMENT_MODES } from 'constants/App';
import { Colors, FontFamilies } from 'constants/Clementine';

import AlqApi from 'utils/AlqApi';
import FormUtils from 'utils/Form';

class PsgEappModal extends React.Component {
  static propTypes = {
    eapp: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props);

    const cookied_info = Cookies.getJSON('ixn_clementine_alq') || {};

    this.state = {
      current_view: cookied_info.psg_hide_how_it_works ? 'agent_signature' : 'how_it_works',
      loading: false,

      errors: [],
      agent_signature_code: cookied_info.psg_agent_signature_code || context.agent.psg_agent_signature_code || '',
      agent_signature_agreement: false,
      agent_email: context.agent.email || '',
      payment_mode: 'monthly'
    };
  }

  _toggleView = current_view => {
    this.setState({ current_view });
  };

  _handleFieldChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  _handleCheckboxChange = name => {
    this.setState({ [name]: !this.state[name] });
  };

  _handleDontShowHowItWorks = () => {
    const cookied_info = Cookies.getJSON('ixn_clementine_alq') || {};

    Cookies.set(
      'ixn_clementine_alq',
      Object.assign({}, cookied_info, {
        psg_hide_how_it_works: true
      }),
      { domain: window.location.hostname }
    );

    this.setState({ current_view: 'agent_signature' });
  };

  _handleStartApplication = () => {
    const { agent_email, agent_signature_code, agent_signature_agreement, payment_mode } = this.state;
    const clean_code = agent_signature_code.trim();
    const errors = [];

    if (!FormUtils._validateEmail(agent_email)) {
      errors.push('agent_email');
    }

    if (clean_code.length !== 32) {
      errors.push('agent_signature_code');
    }

    if (!agent_signature_agreement) {
      errors.push('agent_signature_agreement');
    }

    if (errors.length) {
      this.setState({
        errors
      });
    } else {
      const cookied_info = Cookies.getJSON('ixn_clementine_alq') || {};

      Cookies.set(
        'ixn_clementine_alq',
        Object.assign({}, cookied_info, {
          psg_agent_signature_code: clean_code
        }),
        { domain: window.location.hostname }
      );

      if (!this.props.eapp) {
        this.setState({
          loading: true
        });

        AlqApi.createEApp(
          Object.assign(
            {},
            {
              agent_email: agent_email.toLowerCase(),
              agent_signature_code,
              payment_mode,
              quote_guid: this.props.quote.guid,
              provider: 'psg'
            }
          )
        ).then(response => {
          this.props.onCreate(response.data);

          this.setState({
            errors: [],
            loading: false,
            current_view: 'sent'
          });
        });
      }
    }
  };

  _handleSendContinueLaterLink = () => {
    this.setState({
      current_view: 'continue_later_sending',
      loading: true
    });

    AlqApi.sendEAppLink(this.props.eapp.id).then(() => {
      this.setState({
        current_view: 'continue_later_success',
        loading: false
      });
    });
  };

  _psgEappLaunch = url => {
    window.open(url, '_blank');
  };

  render() {
    const { eapp, onClose, quote } = this.props;
    const { agent_email, agent_signature_agreement, agent_signature_code, current_view, errors, loading, payment_mode } = this.state;
    const styles = this.styles();
    const psg_eapp_base_url = this.context.alq.use_psg_test_servers ? 'https://ixntest.paperlessolutions.net/ffxml' : 'https://ixn.paperlessolutions.net/ffxml';
    const psg_signature_url = this.context.alq.use_psg_test_servers ? 'https://ixntest.paperlessolutions.net/php/capture/' : 'https://ixn.paperlessolutions.net/php/capture/';
    const buttons = {
      error: [
        {
          onClick: onClose,
          children: 'Close This Window'
        }
      ],
      continue_later_success: [
        {
          onClick: this._toggleView.bind(null, 'psg_app'),
          outline: true,
          children: 'Go Back To Application'
        },
        {
          onClick: onClose,
          children: 'Close This Window',
          style: styles.button_right
        }
      ],
      how_it_works: [
        {
          onClick: this._handleDontShowHowItWorks,
          outline: true,
          children: 'Don\'t show "How It Works" again'
        },
        {
          onClick: this._toggleView.bind(null, 'agent_signature'),
          children: 'Start Application',
          style: styles.button_right
        }
      ],
      agent_signature: [
        {
          onClick: this._handleStartApplication,
          style: styles.button_right,
          children: 'Next'
        }
      ]
    };

    return (
      <Modal buttons={buttons[current_view]} loading={loading || current_view === 'continue_later_sending'} maxWidth={1000} onClose={onClose} title='E-App (Powered By Paperless Solutions Group)'>
        <div style={styles.content}>
          {eapp && eapp.provider_status === 'sent' && current_view !== 'continue_later_success' ? (
            <div style={styles.centered_content}>
              <p style={styles.psg_message}>Due to current technical difficulties with our E-App partner, you will need to continue this E-App in a new tab or window.</p>
              <Button fontColor='#FFFFFF' onClick={this._psgEappLaunch.bind(null, `${psg_eapp_base_url}/start.do?xmlID=${eapp.provider_tracking_id}`)} style={styles.psg_eapp_button}>
                Launch PSG E-App
              </Button>
              <div onClick={this._handleSendContinueLaterLink} style={styles.continue_later}>
                Continue Application Later
              </div>
            </div>
          ) : null}

          {eapp && eapp.provider_status === 'error' ? (
            <div style={styles.stacked_content}>
              <div style={styles.centered_content_wrapper}>
                <div style={styles.centered_content}>
                  <div style={styles.tagline}>
                    Error!
                    <div style={styles.progress_bar}>
                      <div style={Object.assign({}, styles.progress, { width: '100%', backgroundColor: Colors.RED.hex })} />
                    </div>
                  </div>
                  <div style={styles.title}>Unable to continue</div>
                  <div style={styles.copy}>{eapp.provider_response}</div>
                </div>
              </div>
            </div>
          ) : null}

          {current_view === 'continue_later_success' ? (
            <div style={styles.centered_content}>
              <div style={styles.tagline}>
                Email Sent!
                <div style={styles.progress_bar}>
                  <div style={Object.assign({}, styles.progress, { width: '100%' })} />
                </div>
              </div>
              <div style={styles.title}>What's Next?</div>
              {[
                'In a few moments you will receive an email with links to the electronic and PDF versions of the application.',
                'Download the PDF version of the application to continue the application process manually.',
                'Click the link to the electronic version to be brought back to these screens. All the information entered up to this point has been saved and will be pre-filled when you return.'
              ].map((item, i) => {
                return (
                  <div key={i} style={styles.list_item}>
                    <div style={styles.list_item_number}>{i + 1}</div>
                    <div style={styles.list_item_content}>{item}</div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {current_view === 'how_it_works' && !eapp ? (
            <div style={styles.split_content}>
              <div style={styles.left_content}>
                <div style={Object.assign({}, styles.bg_left, { backgroundImage: `url("${SA_BASE_URL}/ixn/images/psg_welcome.png")` })} />
              </div>
              <div style={styles.right_content}>
                <div style={styles.title}>How It Works</div>
                <div style={styles.copy}>We make it quick and easy to complete a PDF application and collect all required signatures. Here is how it works:</div>
                {[
                  'Create your electronic signature that will be applied to the application, and can be used on future applications.',
                  'Complete the application using a series of intelligent web forms.',
                  'Trigger an email to your client that will include step by step instructions on how to review and electronically sign the application.',
                  'Receive a completed and signed application via email.',
                  'If needed, you can always email yourself a link to complete the application later.'
                ].map((item, i) => {
                  return (
                    <div key={i} style={styles.list_item}>
                      <div style={styles.list_item_number}>{i + 1}</div>
                      <div style={styles.list_item_content}>{item}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          {current_view === 'agent_signature' && !eapp ? (
            <div style={styles.centered_content}>
              <div style={styles.title}>Start Application</div>
              <div style={styles.copy}>We need a few pieces of information to start the application. All of the following information is required.</div>

              <Field label='Desired Payment Mode'>
                <Select
                  onChange={this._handleFieldChange.bind(null, 'payment_mode')}
                  options={PAYMENT_MODES.map(pm => ({
                    value: pm.value,
                    label: `${pm.label} (${Numeral(quote[`premium_${pm.value}`]).format('$0,0.00')})`
                  }))}
                  required={true}
                  value={payment_mode}
                />
              </Field>

              <Field label='Agent Email'>
                <Input invalid={errors.includes('agent_email')} onChange={this._handleFieldChange.bind(null, 'agent_email')} required={true} value={agent_email} />
              </Field>

              <Field label='Agent Signature Code'>
                <div style={styles.description}>
                  If you already have an Agent Signature Code from a previous application, you can enter it into the field below. Otherwise,{' '}
                  <a href={psg_signature_url} style={styles.link} target='_blank'>
                    Click here to create a new one
                  </a>
                  .
                </div>
                <Input invalid={errors.includes('agent_signature_code')} onChange={this._handleFieldChange.bind(null, 'agent_signature_code')} required={true} value={agent_signature_code} />
              </Field>

              <div style={Object.assign({}, styles.checkbox, errors.includes('agent_signature_agreement') && styles.checkbox_error)}>
                <Checkbox
                  checked={agent_signature_agreement}
                  label='I agree to use this signature to sign this application.'
                  onChange={this._handleCheckboxChange.bind(null, 'agent_signature_agreement')}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    );
  }

  styles = () => {
    return {
      iframe: {
        width: '100%',
        height: '100%',
        border: 'none'
      },
      bg_left: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat'
      },
      content: {
        width: 800,
        position: 'relative'
      },
      centered_content: {
        width: '100%',
        maxWidth: 400,
        margin: '40px auto'
      },
      split_content: {
        display: 'flex'
      },
      left_content: {
        width: '50%',
        overflow: 'auto'
      },
      right_content: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        padding: '40px 60px 40px 30px'
      },
      title: {
        fontSize: 30,
        marginBottom: 20,
        lineHeight: '38px',
        fontFamily: FontFamilies.ACCENT
      },
      tagline: {
        fontSize: 13,
        textTransform: 'uppercase',
        marginBottom: 30
      },
      progress_bar: {
        marginTop: 5,
        height: 4,
        borderRadius: 2,
        backgroundColor: '#e5e5e5',
        position: 'relative',
        overflow: 'hidden'
      },
      progress: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        backgroundColor: Colors.GREEN.hex
      },
      copy: {
        fontSize: 14,
        lineHeight: '1.5em',
        marginBottom: 30
      },
      list_item: {
        marginBottom: 25,
        display: 'flex'
      },
      list_item_number: {
        margin: '3px 15px 0',
        borderRadius: 20,
        width: 20,
        height: 20,
        lineHeight: '20px',
        textAlign: 'center',
        fontSize: 10,
        color: '#ffffff',
        fontWeight: 700,
        flexShrink: 0,
        backgroundColor: this.context.color
      },
      list_item_content: {
        fontSize: 14,
        lineHeight: '20px',
        flex: 1
      },
      button_right: {
        marginLeft: 'auto',
        display: 'flex'
      },
      continue_later: {
        color: this.context.color,
        fontWeight: 600,
        textDecoration: 'underline',
        cursor: 'pointer',
        textAlign: 'center'
      },
      description: {
        fontSize: 12,
        marginBottom: 10,
        lineHeight: '1.4em',
        fontStyle: 'italic',
        color: '#777'
      },
      checkbox: {
        fontSize: 12,
        marginTop: 20,
        color: '#777'
      },
      checkbox_error: {
        color: Colors.RED.hex
      },
      psg_eapp_button: {
        margin: '30px auto 15px',
        textAlign: 'center'
      },
      psg_message: {
        textAlign: 'center',
        marginTop: 60,
        fontSize: 16
      }
    };
  };
}

PsgEappModal.contextType = ProductContext;

export default PsgEappModal;
