import Axios from 'axios';
import Cookies from 'js-cookie';

import AppConfig from 'config/App';

const request = is_external => {
  const ixn_auth = Cookies.getJSON('ixn') || {};
  const token = window.IXN_QUOTER_CONFIG.id;

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };

  if (!is_external) {
    headers['BGA-QUOTER-TOKEN'] = token;
    headers['access-token'] = ixn_auth.access_token;
    headers['client'] = ixn_auth.client;
    headers['uid'] = ixn_auth.uid;
  }

  return Axios.create({
    baseURL: AppConfig.API_BASE_URL,
    headers
  });
};

const AlqApi = {
  loadBgaQuoter() {
    return request().get(`/v1/bga_quotes/${window.IXN_QUOTER_CONFIG.id}`);
  },

  loadCarriers(carrier_ids, beta = false) {
    const params = {
      id: carrier_ids
    };

    if (beta) {
      params.beta = true;
    }

    return request()
      .get('/v1/carriers', { params })
      .then(response => response.data);
  },

  loadProductFeatures() {
    return request()
      .get('/v1/product_features')
      .then(response => response.data);
  },

  loadUnderwritingGuides(carrier_ids) {
    return request()
      .get('/v1/underwriting_guides', {
        params: {
          carrier_id: carrier_ids
        }
      })
      .then(response => response.data);
  },

  calculateNeeds(params) {
    return request()
      .post('/v1/needs_calculations/', params)
      .then(response => {
        return response;
      });
  },

  loadFormTags() {
    return request()
      .get('/v1/form_tags')
      .then(response => {
        return response.data;
      });
  },

  loadQuoteForms(params) {
    return request()
      .get('/v1/forms/', { params })
      .then(response => {
        return response;
      });
  },

  loadAmbestRecord(carrier_id) {
    return request()
      .get('/v1/ambest_records/', { params: { carrier_id } })
      .then(response => {
        return response;
      });
  },

  loadProduct(id) {
    return request().get(`/v1/products/${id}`);
  },

  loadProducts(id) {
    return request().get('/v1/products', { params: { id } });
  },

  getQuotes(params) {
    return request()
      .post('/v1/quotes/', params)
      .then(response => {
        return response;
      });
  },

  emailQuotes(params) {
    return request().post('/v1/pdfs', params);
  },

  textQuotes(params) {
    return request().post('/v1/pdfs', params);
  },

  generateQuotesPdf(params) {
    return request().post('/v1/pdfs', params);
  },

  downloadStandardPacket(params) {
    return request().post('/v1/forms/standard_packet', params);
  },

  generateFormsPdf(params) {
    return request().post('/v1/forms', params);
  },

  requestForms(params) {
    return request().post('/v1/forms/request_forms', params);
  },

  postToWebhook(url, params) {
    return request(true).post(url, params);
  },

  createEticket(params) {
    return request().post('/v1/etickets', params);
  },

  updateEticket(id, params) {
    return request().put(`/v1/etickets/${id}`, params);
  },

  loadEticket(id) {
    return request().get(`/v1/etickets/${id}`);
  },
  getHorizonsLink(id) {
    return request().get(`/v1/etickets_horizon/${id}`);
  },

  createEApp(params) {
    return request().post('/v1/e_apps', params);
  },

  updateEApp(id, params) {
    return request().put(`/v1/e_apps/${id}`, params);
  },

  sendEAppLink(xml_id, params) {
    return request().post(`/v1/e_apps/${xml_id}/send_link`, params);
  },

  checkNewMemberEmail(email) {
    return request().post('/v1/member_exists', { email });
  },

  login(params) {
    return request().post('/v1/members/login', params);
  },

  logout() {
    return request().delete('/v1/members/logout');
  },

  loadMember(member_id) {
    return request().get(`/v1/members/${member_id}`);
  },

  register(params) {
    return request().post('/v1/members', params);
  },

  sendFeedback(params) {
    return request().post('/v1/feedback', params);
  }
};

export default AlqApi;
