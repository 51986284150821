import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';

import Field from 'shared/Field';
import Select from 'shared/Select';

const Billing = ({ errors, formData, onInputChange, quote }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Field label='Payment Mode' style={{ width: 'calc(50% - 5px)' }}>
        <Select
          invalid={errors.includes('billing_method')}
          onChange={onInputChange.bind(null, 'billing_method', null)}
          options={[
            { value: '', label: '-- Select One --' },
            { value: 'direct_billing', label: 'Direct Billing' },
            { value: 'eft', label: 'EFT' }
          ]}
          required={true}
          value={formData.billing_method}
        />
      </Field>
      <Field label='Payment Frequency' style={{ width: 'calc(50% - 5px)' }}>
        <Select
          invalid={errors.includes('billing_frequency')}
          onChange={onInputChange.bind(null, 'billing_frequency', null)}
          options={[
            { value: '', label: '-- Select One --' },
            { value: 'monthly', label: `Monthly (${Numeral(quote.premium_monthly).format('$0.00')})` },
            { value: 'quarterly', label: `Quarterly (${Numeral(quote.premium_quarterly).format('$0.00')})` },
            { value: 'semi_annual', label: `Semi-Annual (${Numeral(quote.premium_semi_annual).format('$0.00')})` },
            { value: 'annual', label: `Annual (${Numeral(quote.premium_annual).format('$0.00')})` }
          ]}
          required={true}
          value={formData.billing_frequency}
        />
      </Field>
    </div>
  );
};

Billing.propTypes = {
  errors: PropTypes.array,
  formData: PropTypes.object,
  onInputChange: PropTypes.func,
  quote: PropTypes.object
};

export default Billing;
