import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Range, getTrackBackground } from 'react-range';
import { Line } from 'react-chartjs-2';

import _groupBy from 'lodash/groupBy';
import _ from 'lodash';

const RangePicker = ({ color, id = 'range-picker', max, min, onChange, rangeValues, renderLabel, values }) => {
  const [modifiedValuesList, setModifiedValuesList] = useState([]);
  const sortValues = _.sortBy(values);
  const min_val = modifiedValuesList[0];
  const max_val = modifiedValuesList[modifiedValuesList.length - 1];

  useEffect(() => {
    if (sortValues[0] > 1000) {
      let results = [];
      let minVal = min.toString().split('').map(Number).length;
      let minValZeroCount = minVal.toString().padStart(minVal, '0').slice(0, -2);

      for (let i = 0; i < sortValues.length; i++) {
        let num = sortValues[i];

        let modifiedNum = Math.floor(num / parseInt(1 + minValZeroCount));
        results.push(modifiedNum);
      }
      setModifiedValuesList(results);
    } else {
      setModifiedValuesList(sortValues);
    }
  }, []);

  const _getChartData = (min, max, values) => {
    const data = [];
    const counts = _groupBy(values, Math.floor);

    for (let x = min; x <= max; x++) {
      data.push({
        x,
        y: counts[x] ? counts[x].length : 0
      });
    }

    return data;
  };

  return (
    <React.Fragment>
      <div id={id} style={{ margin: '10px 10px -34px', opacity: 0.2, borderBottom: `2px solid ${color}` }}>
        <Line
          data={{
            datasets: [
              {
                fill: true,
                lineTension: 0.4,
                backgroundColor: color,
                borderColor: 'transparent',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'transparent',
                pointBackgroundColor: 'transparent',
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: 'transparent',
                pointHoverBorderColor: 'transparent',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                data: _getChartData(min_val, max_val, modifiedValuesList)
              }
            ]
          }}
          height={80}
          options={{
            legend: {
              display: false
            },
            scales: {
              yAxes: [
                {
                  display: false,
                  ticks: {
                    beginAtZero: true
                  }
                }
              ],
              xAxes: [
                {
                  display: false,
                  type: 'linear',
                  ticks: {
                    min_val,
                    max_val,
                    stepSize: 1
                  }
                }
              ]
            },
            tooltips: {
              enabled: false
            }
          }}
        />
      </div>
      <div style={{ margin: '18px 10px 0px' }}>
        <Range
          max={max}
          min={min}
          onChange={e => onChange(e)}
          renderThumb={({ index, props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: 30,
                width: 30,
                borderRadius: 0,
                backgroundColor: 'transparent',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: 'none'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: -10,
                  color,
                  fontWeight: 600,
                  fontSize: 12,
                  marginLeft: -1
                }}
              >
                {renderLabel(rangeValues[index])}
              </div>
              <div
                style={{
                  height: 18,
                  width: 18,
                  borderRadius: 18,
                  background: '#fff',
                  border: `5px solid ${color}`
                }}
              />
            </div>
          )}
          renderTrack={({ props, children }) => (
            <div
              style={{
                ...props.style,
                height: 30,
                display: 'flex',
                width: '100%'
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: 2,
                  width: '100%',
                  background: getTrackBackground({
                    values: rangeValues,
                    colors: ['transparent', color, 'transparent'],
                    min,
                    max
                  }),
                  alignSelf: 'center'
                }}
              >
                {children}
              </div>
            </div>
          )}
          step={1}
          values={rangeValues}
        />
      </div>
    </React.Fragment>
  );
};

RangePicker.propTypes = {
  color: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  rangeValues: PropTypes.array,
  renderLabel: PropTypes.func,
  step: PropTypes.number,
  values: PropTypes.array
};

export default RangePicker;
