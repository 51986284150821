import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import DatePicker from 'react-datepicker';
import 'css/ReactDatepicker.css';

import { ProductContext } from 'alq/Context';

import Field from 'shared/Field';
import Input from 'shared/Input';
import Modal from 'shared/Modal';
import QuoteSimple from 'shared/QuoteSimple';

import AlqApi from 'utils/AlqApi';
import FormUtils from 'utils/Form';

class ApplicintModal extends React.Component {
  static propTypes = {
    onBackClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    quote: PropTypes.object
  };

  constructor(props, context) {
    super(props);

    this.state = {
      current_view: 'enter_information',
      show_quote: false,
      loading: false,
      errors: [],

      first_name: context.client.first_name,
      last_name: context.client.last_name,
      email: '',
      social_security_number: '',
      street1: '',
      city: '',
      zip: '',
      phone: '',
      date_of_birth: context.ran_quote_data.date_of_birth
    };
  }

  _toggleQuote = () => {
    this.setState({
      show_quote: !this.state.show_quote
    });
  };

  _handleFieldChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  };

  _handleDobChange = (name, date_of_birth) => {
    this.setState({
      date_of_birth
    });
  };

  _startApplication = () => {
    const errors = [];
    const { first_name, last_name, email, street1, city, zip, phone, social_security_number, date_of_birth } = this.state;

    if (!first_name.length) {
      errors.push('first_name');
    }

    if (!last_name.length) {
      errors.push('last_name');
    }

    if (!FormUtils._validateEmail(email)) {
      errors.push('email');
    }

    if (!street1.length) {
      errors.push('street1');
    }

    if (!city.length) {
      errors.push('city');
    }

    if (!zip.length) {
      errors.push('zip');
    }

    if (!FormUtils._validatePhone(phone)) {
      errors.push('phone');
    }

    if (social_security_number.length < 9) {
      errors.push('social_security_number');
    }

    if (!date_of_birth) {
      errors.push('date_of_birth');
    }

    if (errors.length) {
      this.setState({
        errors
      });
    } else {
      this.setState({
        loading: true,
        errors: []
      });

      AlqApi.createEticket({
        agency_id: this.context.alq.agency_id,
        first_name,
        last_name,
        email,
        street1,
        city,
        state: this.props.quote.state,
        zip,
        phone,
        provider: 'applicint',
        provider_status: 'new',
        agent_email: this.context.agent.email,
        quote_guid: this.props.quote.guid,
        source_type: 'BgaQuoter',
        source_guid: window.IXN_QUOTER_CONFIG.id,
        form_fields: JSON.stringify({
          pi_first_name: first_name,
          pi_last_name: last_name,
          pi_email: email,
          pi_address: street1,
          pi_city: city,
          pi_state: this.props.quote.state,
          pi_zip: zip,
          pi_home_phone: phone,
          social_security_number,
          date_of_birth: Moment(date_of_birth).format('YYYY-MM-DD'),
          applicint_trusted_code: this.context.agent.applicint_trusted_code,
          agent_email: this.context.agent.email,
          agent_first_name: this.context.agent.first_name,
          agent_last_name: this.context.agent.last_name
        })
      })
        .then(response => {
          this.setState({
            loading: false,
            response: response.data.provider_response,
            current_view: response.data.provider_status === 'error' ? 'error' : 'success'
          });

          if (response.data.provider_tracking_code) {
            window.open(response.data.provider_tracking_code, '_blank');
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            current_view: 'error',
            response: 'We were unable to send the information to ApplicInt at this time. Please try again later.'
          });
        });
    }
  };

  render() {
    const { onClose, quote } = this.props;
    const { loading, current_view, errors, response, first_name, last_name, email, street1, city, zip, phone, social_security_number, date_of_birth } = this.state;
    const styles = this.styles();
    const buttons = {
      enter_information: [
        {
          children: 'View Quote',
          disabled: loading,
          onClick: this._toggleQuote,
          outline: true
        },
        {
          children: 'Back',
          onClick: this.props.onBackClick,
          color: '#e5e5e5',
          fontColor: '#999',
          style: { margin: '0 10px 0 auto' }
        },
        {
          children: 'Next',
          disabled: loading,
          onClick: this._startApplication
        }
      ],
      success: [
        {
          children: 'Back to Quotes',
          onClick: this.props.onClose,
          style: { marginLeft: 'auto' }
        }
      ],
      error: [
        {
          children: 'Back to Quotes',
          onClick: this.props.onClose,
          style: { marginLeft: 'auto' }
        }
      ]
    };
    const titles = {
      enter_information: 'ExpressComplete: Insured Information',
      success: 'Information Sent to ExpressComplete',
      error: 'Something Went Wrong'
    };

    return (
      <Modal buttons={buttons[current_view]} loading={loading} maxWidth={800} onClose={onClose} title={titles[current_view]}>
        <div style={styles.content}>
          {current_view === 'enter_information' ? (
            <React.Fragment>
              <QuoteSimple quote={quote} style={styles.quote} />

              <div style={styles.row}>
                <Field label='First Name' style={{ width: '48%' }}>
                  <Input invalid={errors.includes('first_name')} onChange={this._handleFieldChange.bind(null, 'first_name')} required={true} value={first_name} />
                </Field>
                <Field label='Last Name' style={{ width: '48%' }}>
                  <Input invalid={errors.includes('last_name')} onChange={this._handleFieldChange.bind(null, 'last_name')} required={true} value={last_name} />
                </Field>
              </div>

              <Field label='Address'>
                <Input invalid={errors.includes('street1')} onChange={this._handleFieldChange.bind(null, 'street1')} required={true} value={street1} />
              </Field>

              <div style={styles.row}>
                <Field label='City' style={{ width: '31%' }}>
                  <Input invalid={errors.includes('city')} onChange={this._handleFieldChange.bind(null, 'city')} required={true} value={city} />
                </Field>
                <Field label='State' style={{ width: '31%' }}>
                  <Input readOnly={true} value={quote.state} />
                </Field>
                <Field label='Zip Code' style={{ width: '31%' }}>
                  <Input invalid={errors.includes('zip')} onChange={this._handleFieldChange.bind(null, 'zip')} required={true} value={zip} />
                </Field>
              </div>

              <div style={styles.row}>
                <Field label='Phone' style={{ width: '48%' }}>
                  <Input format='phone' invalid={errors.includes('phone')} onChange={this._handleFieldChange.bind(null, 'phone')} required={true} value={phone} />
                </Field>
                <Field label='Email' style={{ width: '48%' }}>
                  <Input invalid={errors.includes('email')} onChange={this._handleFieldChange.bind(null, 'email')} required={true} value={email} />
                </Field>
              </div>

              <Field label='Social Security Number'>
                <Input
                  format='ssn'
                  invalid={errors.includes('social_security_number')}
                  onChange={this._handleFieldChange.bind(null, 'social_security_number')}
                  required={true}
                  value={social_security_number}
                />
              </Field>

              <Field label='Date of Birth'>
                <DatePicker
                  dropdownMode='select'
                  onChange={this._handleDobChange.bind(null, 'date_of_birth')}
                  popperPlacement='top-start'
                  selected={new Date(date_of_birth)}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                />
              </Field>
            </React.Fragment>
          ) : null}

          {current_view === 'success' ? (
            <div style={styles.response}>
              You're almost done! The information you have entered has been stored in the Dashboard and you will now be redirected to ApplicInt's ExpressComplete to finish the E-Ticket process.
              ExpressComplete login credentials will be required to continue. Please contact your Agency if you do not have these.
            </div>
          ) : null}

          {current_view === 'error' ? <div style={styles.response}>{response}</div> : null}
        </div>
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        padding: 40,
        position: 'relative',
        overflow: 'hidden'
      },
      row: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      response: {
        textAlign: 'center',
        lineHeight: '1.8em',
        width: '80%',
        margin: '0 auto'
      },
      quote: {
        position: 'absolute',
        bottom: 0,
        right: 20,
        left: 20,
        borderRadius: '4px 4px 0px 0px',
        transform: this.state.show_quote ? 'translateY(0%)' : 'translateY(100%)',
        transition: 'all .2s',
        zIndex: 1
      }
    };
  };
}

ApplicintModal.contextType = ProductContext;

export default ApplicintModal;
