import React from 'react';
import PropTypes from 'prop-types';

import Field from 'shared/Field';
import Input from 'shared/Input';
import Select from 'shared/Select';

import { RELATIONSHIPS_CONDENSED } from 'constants/Eticket';

const AgentReport = ({ errors, formData, onInputChange }) => {
  return (
    <React.Fragment>
      <Field label='How many years have you known the proposed insured?'>
        <Input
          invalid={errors.includes('agent_years_knowing_pi')}
          onChange={onInputChange.bind(null, 'agent_years_knowing_pi', null)}
          required={true}
          type='number'
          value={formData.agent_years_knowing_pi}
        />
      </Field>

      <Field label='Proposed insured relationship to you or your spouse'>
        <Select
          invalid={errors.includes('agent_pi_relationship')}
          onChange={onInputChange.bind(null, 'agent_pi_relationship', null)}
          options={[{ value: '', label: '-- Select One --' }].concat(RELATIONSHIPS_CONDENSED)}
          required={true}
          value={formData.agent_pi_relationship}
        />
      </Field>

      <Field label='Names of other family members with applications being submitted:'>
        <Input onChange={onInputChange.bind(null, 'family_member_names_with_applications', null)} value={formData.family_member_names_with_applications} />
      </Field>

      <Field label="What is the amount of insurance on the proposed insured's spouse?">
        <Input format='currency' onChange={onInputChange.bind(null, 'insurance_on_pi_spouse', null)} value={formData.insurance_on_pi_spouse} />
      </Field>

      <Field label='Special notes and comments'>
        <Input onChange={onInputChange.bind(null, 'agent_notes', null)} style={{ height: 200 }} type='textarea' value={formData.agent_notes} />
      </Field>
    </React.Fragment>
  );
};

AgentReport.propTypes = {
  errors: PropTypes.array,
  formData: PropTypes.object,
  onInputChange: PropTypes.func
};

export default AgentReport;
