import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';

import { ThemeContext } from 'shared/ThemeContext';

import { Colors, FontWeights, FontSizes } from 'constants/Clementine';

class Guide extends React.Component {
  static propTypes = {
    autoStart: PropTypes.bool,
    continuous: PropTypes.bool,
    defaultIndex: PropTypes.number,
    locale: PropTypes.object,
    onChange: PropTypes.func,
    run: PropTypes.bool,
    showSkipButton: PropTypes.bool,
    steps: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      current_index: props.defaultIndex || 0
    };
  }

  _handleChange = state => {
    if (state.step.customCallback) {
      state.step.customCallback(state);
    }

    if (state.action === 'next' && state.lifecycle === 'complete') {
      this.setState({
        current_index: state.index + 1
      });
    } else if (state.action === 'prev' && state.lifecycle === 'complete') {
      this.setState({
        current_index: state.index - 1
      });
    }

    if (this.props.onChange) {
      this.props.onChange(state);
    }
  };

  render() {
    const { autoStart, continuous, locale, run, steps } = this.props;

    const labels = Object.assign({}, { back: 'Back', close: 'Close', last: 'Finish', next: 'Next', skip: 'Exit' }, locale);

    return (
      <Joyride
        autoStart={autoStart}
        callback={this._handleChange}
        continuous={continuous}
        disableOverlayClose={true}
        locale={labels}
        run={run}
        showSkipButton={true}
        stepIndex={this.state.current_index}
        steps={steps}
        styles={{
          buttonNext: {
            height: 30,
            lineHeight: '30px',
            textAlign: 'center',
            borderRadius: 4,
            fontSize: FontSizes.REGULAR,
            fontWeight: FontWeights.MEDIUM,
            padding: '0px 10px'
          },
          buttonBack: {
            height: 30,
            lineHeight: '30px',
            textAlign: 'center',
            borderRadius: 4,
            fontSize: FontSizes.REGULAR,
            fontWeight: FontWeights.MEDIUM,
            padding: '0px 10px',
            backgroundColor: '#e5e5e5',
            color: Colors.GRAY.hex,
            marginRight: 6
          },
          buttonSkip: {
            fontSize: FontSizes.REGULAR,
            fontWeight: FontWeights.MEDIUM
          },
          buttonClose: {
            display: 'none'
          },
          spotlight: {
            backgroundColor: '#999'
          },
          options: {
            primaryColor: this.context.color,
            textColor: Colors.GRAY.hex
          }
        }}
      />
    );
  }
}

Guide.contextType = ThemeContext;

export default Guide;
