import React from 'react';
import PropTypes from 'prop-types';

import Field from 'shared/Field';
import Input from 'shared/Input';
import Select from 'shared/Select';

import { YES_NO, STATES, COUNTRIES } from 'constants/Eticket';

const ProposedInsured = ({ errors, formData, onInputChange, quote }) => {
  const styles = {
    row: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  };

  return (
    <React.Fragment>
      <Field label='Do you intend to replace or change an existing policy?'>
        <Select
          onChange={onInputChange.bind(null, 'intent_to_replace', parent_scope => {
            const value = parent_scope.state.form_data.intent_to_replace;

            if (value === 'yes') {
              parent_scope.setState({
                hard_stop: true,
                hard_stop_attribute: 'intent_to_replace',
                hard_stop_reason: 'This product is not eligible to replace or change an existing policy.'
              });
            }
          })}
          options={YES_NO}
          value={formData.intent_to_replace}
        />
      </Field>

      <div style={styles.row}>
        <Field label='First Name' style={{ width: 'calc(50% - 50px)' }}>
          <Input invalid={errors.includes('first_name')} maxLength={20} onChange={onInputChange.bind(null, 'first_name', null)} required={true} value={formData.first_name} />
        </Field>
        <Field label='Middle Initial' style={{ width: 80, margin: '0 10px' }}>
          <Input maxLength={10} onChange={onInputChange.bind(null, 'middle_initial', null)} value={formData.middle_initial} />
        </Field>
        <Field label='Last Name' style={{ width: 'calc(50% - 50px)' }}>
          <Input invalid={errors.includes('last_name')} maxLength={40} onChange={onInputChange.bind(null, 'last_name', null)} required={true} value={formData.last_name} />
        </Field>
      </div>
      <Field label='Street Address'>
        <Input invalid={errors.includes('address')} maxLength={35} onChange={onInputChange.bind(null, 'address', null)} required={true} value={formData.address} />
      </Field>
      <div style={styles.row}>
        <Field label='City' style={{ width: 'calc(60% - 35px)' }}>
          <Input invalid={errors.includes('city')} maxLength={24} onChange={onInputChange.bind(null, 'city', null)} required={true} value={formData.city} />
        </Field>
        <Field label='State' style={{ width: 50, margin: '0 10px' }}>
          <Input readOnly={true} value={quote.state} />
        </Field>
        <Field label='Zip Code' style={{ width: 'calc(40% - 35px)' }}>
          <Input invalid={errors.includes('zip')} maxLength={5} onChange={onInputChange.bind(null, 'zip', null)} required={true} value={formData.zip} />
        </Field>
      </div>
      <div style={styles.row}>
        <Field label='Primary Phone' style={{ width: 'calc(50% - 5px)' }}>
          <Input format='phone' invalid={errors.includes('phone_primary')} onChange={onInputChange.bind(null, 'phone_primary', null)} required={true} value={formData.phone_primary} />
        </Field>
        <Field label='Secondary Phone' style={{ width: 'calc(50% - 5px)' }}>
          <Input format='phone' invalid={errors.includes('phone_secondary')} onChange={onInputChange.bind(null, 'phone_secondary', null)} value={formData.phone_secondary} />
        </Field>
      </div>
      <Field label='Email Address'>
        <Input maxLength={256} onChange={onInputChange.bind(null, 'email', null)} value={formData.email} />
      </Field>

      <div style={styles.row}>
        <Field label='Age' style={{ width: 50, marginRight: 10 }}>
          <Input readOnly={true} value={formData.age} />
        </Field>
        <Field label='Date of Birth' style={{ flex: 1 }}>
          <div style={{ display: 'flex' }}>
            <Select
              invalid={errors.includes('dob_month')}
              onChange={onInputChange.bind(null, 'dob_month', null)}
              options={[
                { value: '', label: '--' },
                { value: '01', label: 'January' },
                { value: '02', label: 'February' },
                { value: '03', label: 'March' },
                { value: '04', label: 'April' },
                { value: '05', label: 'May' },
                { value: '06', label: 'June' },
                { value: '07', label: 'July' },
                { value: '08', label: 'August' },
                { value: '09', label: 'September' },
                { value: '10', label: 'October' },
                { value: '11', label: 'November' },
                { value: '12', label: 'December' }
              ]}
              required={true}
              style={{ marginRight: -1, borderRadius: '4px 0px 0px 4px', minWidth: 120 }}
              value={formData.dob_month}
            />
            <Select
              invalid={errors.includes('dob_day')}
              onChange={onInputChange.bind(null, 'dob_day', null)}
              options={[
                { value: '', label: '--' },
                { value: '01', label: '1' },
                { value: '02', label: '2' },
                { value: '03', label: '3' },
                { value: '04', label: '4' },
                { value: '05', label: '5' },
                { value: '06', label: '6' },
                { value: '07', label: '7' },
                { value: '08', label: '8' },
                { value: '09', label: '9' },
                { value: '10', label: '10' },
                { value: '11', label: '11' },
                { value: '12', label: '12' },
                { value: '13', label: '13' },
                { value: '14', label: '14' },
                { value: '15', label: '15' },
                { value: '16', label: '16' },
                { value: '17', label: '17' },
                { value: '18', label: '18' },
                { value: '19', label: '19' },
                { value: '20', label: '20' },
                { value: '21', label: '21' },
                { value: '22', label: '22' },
                { value: '23', label: '23' },
                { value: '24', label: '24' },
                { value: '25', label: '25' },
                { value: '26', label: '26' },
                { value: '27', label: '27' },
                { value: '28', label: '28' },
                { value: '29', label: '29' },
                { value: '30', label: '30' },
                { value: '31', label: '31' }
              ]}
              required={true}
              style={{ marginRight: -1, borderRadius: 0, minWidth: 50, zIndex: 1 }}
              value={formData.dob_day}
            />
            <Input readOnly={true} style={{ borderRadius: '0px 4px 4px 0px', minWidth: 75 }} value={formData.dob_year} />
          </div>
        </Field>
      </div>

      <div style={styles.row}>
        <Field label='Country of Birth' style={{ width: 'calc(50% - 5px)' }}>
          <Select
            invalid={errors.includes('country_of_birth')}
            onChange={onInputChange.bind(null, 'country_of_birth', null)}
            options={[{ value: '', label: '-- Select One --' }].concat(COUNTRIES)}
            required={true}
            value={formData.country_of_birth}
          />
        </Field>
        <Field label='State of Birth' style={{ width: 'calc(50% - 5px)' }}>
          <Select
            invalid={errors.includes('state_of_birth')}
            onChange={onInputChange.bind(null, 'state_of_birth', null)}
            options={[{ value: '', label: '-- Select One --' }].concat(STATES).concat([{ value: 'NA', label: 'N/A' }])}
            required={true}
            value={formData.state_of_birth}
          />
        </Field>
      </div>

      <Field label='Social Security Number'>
        <Input format='ssn' invalid={errors.includes('ssn')} onChange={onInputChange.bind(null, 'ssn', null)} required={true} value={formData.ssn} />
      </Field>
      <Field label='Proof of U.S. Citizenship'>
        <Select
          invalid={errors.includes('citizenship_proof')}
          onChange={onInputChange.bind(null, 'citizenship_proof', parent_scope => {
            const value = parent_scope.state.form_data.citizenship_proof;

            if (value === 'none') {
              parent_scope.setState({
                hard_stop: true,
                hard_stop_attribute: 'citizenship_proof',
                hard_stop_reason: 'The proposed insured does not have valid proof of U.S. citizenship.'
              });
            } else {
              parent_scope.setState({
                form_data: Object.assign({}, parent_scope.state.form_data, {
                  citizenship_permanent_resident: value === 'permanent_resident_card'
                })
              });
            }
          })}
          options={[
            { value: '', label: '-- Select One --' },
            { value: 'state_dl', label: 'State Drivers License' },
            { value: 'state_id', label: 'State ID' },
            { value: 'passport', label: 'Passport' },
            { value: 'permanent_resident_card', label: 'Permanent Resident Card' },
            { value: 'none', label: 'None' }
          ]}
          required={true}
          value={formData.citizenship_proof}
        />
      </Field>
      {formData.citizenship_proof === 'state_dl' ? (
        <>
          <Field label='Drivers License Number'>
            <Input invalid={errors.includes('citizenship_dl_number')} onChange={onInputChange.bind(null, 'citizenship_dl_number', null)} required={true} value={formData.citizenship_dl_number} />
          </Field>
          <div style={styles.row}>
            <Field label='Drivers License State' style={{ width: 'calc(50% - 5px)' }}>
              <Select
                invalid={errors.includes('citizenship_dl_state')}
                onChange={onInputChange.bind(null, 'citizenship_dl_state', null)}
                options={[{ value: '', label: '-- Select One --' }].concat(STATES)}
                required={true}
                value={formData.citizenship_dl_state}
              />
            </Field>
            <Field label='Drivers License Expiration Date' style={{ width: 'calc(50% - 5px)' }}>
              <Input
                invalid={errors.includes('citizenship_dl_expiration_date')}
                onChange={onInputChange.bind(null, 'citizenship_dl_expiration_date', null)}
                required={true}
                value={formData.citizenship_dl_expiration_date}
              />
            </Field>
          </div>
        </>
      ) : null}
      {formData.citizenship_proof === 'state_id' ? (
        <>
          <Field label='State ID Number'>
            <Input invalid={errors.includes('citizenship_id_number')} onChange={onInputChange.bind(null, 'citizenship_id_number', null)} required={true} value={formData.citizenship_id_number} />
          </Field>
          <div style={styles.row}>
            <Field label='State ID State' style={{ width: 'calc(50% - 5px)' }}>
              <Select
                invalid={errors.includes('citizenship_id_state')}
                onChange={onInputChange.bind(null, 'citizenship_id_state', null)}
                options={[{ value: '', label: '-- Select One --' }].concat(STATES)}
                required={true}
                value={formData.citizenship_id_state}
              />
            </Field>
            <Field label='State ID Expiration Date' style={{ width: 'calc(50% - 5px)' }}>
              <Input
                invalid={errors.includes('citizenship_id_expiration_date')}
                onChange={onInputChange.bind(null, 'citizenship_id_expiration_date', null)}
                required={true}
                value={formData.citizenship_id_expiration_date}
              />
            </Field>
          </div>
        </>
      ) : null}
      {formData.citizenship_proof === 'passport' ? (
        <>
          <Field label='Passport Number'>
            <Input
              invalid={errors.includes('citizenship_passport_number')}
              onChange={onInputChange.bind(null, 'citizenship_passport_number', null)}
              required={true}
              value={formData.citizenship_passport_number}
            />
          </Field>
          <div style={styles.row}>
            <Field label='Passport Issue Country' style={{ width: 'calc(50% - 5px)' }}>
              <Select
                invalid={errors.includes('citizenship_passport_country')}
                onChange={onInputChange.bind(null, 'citizenship_passport_country', null)}
                options={[{ value: '', label: '-- Select One --' }].concat(COUNTRIES)}
                required={true}
                value={formData.citizenship_passport_country}
              />
            </Field>
            <Field label='Passport Expiration Date' style={{ width: 'calc(50% - 5px)' }}>
              <Input
                invalid={errors.includes('citizenship_passport_expiration_date')}
                onChange={onInputChange.bind(null, 'citizenship_passport_expiration_date', null)}
                required={true}
                value={formData.citizenship_passport_expiration_date}
              />
            </Field>
          </div>
        </>
      ) : null}
      {formData.citizenship_proof === 'permanent_resident_card' ? (
        <React.Fragment>
          <Field label='Country of Origin'>
            <Select
              invalid={errors.includes('citizenship_country_of_origin')}
              onChange={onInputChange.bind(null, 'citizenship_country_of_origin', null)}
              options={[{ value: '', label: '-- Select One --' }].concat(COUNTRIES)}
              required={true}
              value={formData.citizenship_country_of_origin}
            />
          </Field>
          <Field label='Date of U.S. Entry'>
            <Input
              invalid={errors.includes('citizenship_date_of_entry')}
              onChange={onInputChange.bind(null, 'citizenship_date_of_entry', null)}
              required={true}
              value={formData.citizenship_date_of_entry}
            />
          </Field>
          <Field label='Current Country of Citizenship'>
            <Select
              invalid={errors.includes('citizenship_current_country')}
              onChange={onInputChange.bind(null, 'citizenship_current_country', null)}
              options={[{ value: '', label: '-- Select One --' }].concat(COUNTRIES)}
              required={true}
              value={formData.citizenship_current_country}
            />
          </Field>
          <Field label='Green Card/Visa Number'>
            <Input invalid={errors.includes('citizenship_visa_number')} onChange={onInputChange.bind(null, 'citizenship_visa_number', null)} required={true} value={formData.citizenship_visa_number} />
          </Field>
          <Field label='Green Card/Visa Expiration Date'>
            <Input
              invalid={errors.includes('citizenship_visa_expiration_date')}
              onChange={onInputChange.bind(null, 'citizenship_visa_expiration_date', null)}
              required={true}
              value={formData.citizenship_visa_expiration_date}
            />
          </Field>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

ProposedInsured.propTypes = {
  errors: PropTypes.array,
  formData: PropTypes.object,
  onInputChange: PropTypes.func,
  quote: PropTypes.object
};

export default ProposedInsured;
