import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'shared/Modal';

import AlqApi from 'utils/AlqApi';

import { FontFamilies, FontSizes, FontWeights } from 'constants/Clementine';

class PostToUrlModal extends React.Component {
  static propTypes = {
    action: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      success: false,
      success_title: '',
      success_msg: '',
      error: false,
      error_title: '',
      error_msg: ''
    };
  }

  componentDidMount() {
    const { action, params } = this.props;

    AlqApi.postToWebhook(action.url, params)
      .then(response => {
        const data = response.data && response.data.data ? response.data.data : {};

        this.setState({
          success: true,
          success_title: data.title || 'Success!',
          success_msg: data.message || this.props.action.success_msg,
          error: false
        });
      })
      .catch(e => {
        const errors = e.response && e.response.data ? e.response.data.errors : [];
        const error = errors[0] || {};

        this.setState({
          error: true,
          error_title: error.title || 'Error!',
          error_msg: error.detail || this.props.action.error_msg,
          success: false
        });
      });
  }

  render() {
    const { success, error, success_title, success_msg, error_title, error_msg } = this.state;
    const { onClose } = this.props;
    const styles = this.styles();

    return (
      <Modal loading={!success && !error} maxWidth={600} onClose={onClose}>
        <div style={styles.content}>
          {success ? (
            <div>
              <div style={styles.title}>{success_title}</div>
              <div style={styles.message}>{success_msg}</div>
            </div>
          ) : null}

          {error ? (
            <div>
              <div style={styles.title}>{error_title}</div>
              <div style={styles.message}>{error_msg}</div>
            </div>
          ) : null}
        </div>
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        padding: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      },
      title: {
        fontSize: FontSizes.XXLARGE,
        fontWeight: FontWeights.BOLD,
        fontFamily: FontFamilies.ACCENT,
        marginBottom: 20
      },
      message: {
        fontSize: 14,
        lineHeight: '1.6em'
      }
    };
  };
}

export default PostToUrlModal;
