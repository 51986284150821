import React from 'react';
import PropTypes from 'prop-types';

import { ProductContext } from 'alq/Context';

import Modal from 'shared/Modal';

import AlqApi from 'utils/AlqApi';
import { LIFEDX_EMBED_BASE_URL } from 'config/App';

class LifedxEticketModal extends React.Component {
  static propTypes = {
    eticketGuid: PropTypes.string,
    formData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
      })
    ),
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    provider: PropTypes.string,
    quote: PropTypes.object,
    title: PropTypes.string,
    token: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: [],
      hard_stop: false,
      hard_stop_attribute: null,
      hard_stop_reason: '',
      loading: true,
      eticket_success: false,
      eticket: null
    };

    this.contentRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ loading: true });

    const loadEticketPromise = this.props.eticketGuid
      ? AlqApi.loadEticket(this.props.eticketGuid).then(({ data }) => {
          this.setState({ eticket: data });
        })
      : Promise.resolve();

    const createEticketPromise = !this.props.eticketGuid
      ? AlqApi.createEticket(this._getPayload()).then(response => {
          this.props.onCreate(response.data.guid);
          this.setState({
            eticket: response.data,
            eticket_success: true
          });
        })
      : Promise.resolve();

    Promise.all([loadEticketPromise, createEticketPromise])
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        //TODO: Handle error
        this.setState({ loading: false });
      });
  }

  _getPayload = () => {
    const payload = {
      agent_email: this.context.agent.email,
      agency_id: this.context.alq.agency_id,
      quote_guid: this.props.quote.guid,
      source_type: 'BgaQuoter',
      source_guid: window.IXN_QUOTER_CONFIG.id,
      provider: this.props.provider,
      provider_status: 'new'
    };
    const form_fields = { uses_lifedx: true };

    this.props.formData.forEach(f => {
      form_fields[f.name] = f.value;
    });

    payload.form_fields = JSON.stringify(form_fields);

    return payload;
  };

  _handleLifedxEticketDivLoaded = () => {
    const existing_script = document.getElementById('lifedx_eticket_script');
    const existing_style = document.getElementById('lifedx_eticket_style');

    window.LIFEDX_ETICKET = {
      token: this.props.token,
      quote_id: this.props.quote.id,
      form_data: this.props.formData,
      onEvent: (type, payload) => {
        let form_fields = {};

        switch (type) {
          case 'eticket_loaded':
            // We can assume that the data in LifeDX has already been saved to ironsides, this event will only happen if
            // the user clicked out of the action and back into the action, which triggered the LifeDX embed to reload.

            break;
          case 'eticket_created':
            this._updateEticket({ provider_tracking_code: payload.data.id, provider_status: 'sent' });
            break;
          case 'eticket_updated':
            form_fields = this._getFormFieldsFromLifeDX(payload.data.form_data);

            this._updateEticket({ form_fields: JSON.stringify(form_fields) });
            break;
          case 'eticket_error':
            this._updateEticket({ provider_status: 'error' });
            break;
          case 'eticket_submitted':
            form_fields = this._getFormFieldsFromLifeDX(payload.data.form_data);

            this._updateEticket({ form_fields: JSON.stringify(form_fields), provider_status: 'completed' });
            break;
          case 'window_closed':
            this.props.onClose();
            break;
          case 'next_screen':
            this.contentRef.current.scrollIntoView();
            break;
          case 'previous_screen':
            this.contentRef.current.scrollIntoView();
            break;
        }
      }
    };

    if (this.state.eticket.provider_tracking_code) {
      window.LIFEDX_ETICKET.eticket_id = this.state.eticket.provider_tracking_code;
    }

    if (!existing_script) {
      const script = document.createElement('script');

      script.src = `${LIFEDX_EMBED_BASE_URL}/eticket/js/eticket.widget.js`;
      script.type = 'text/javascript';
      script.id = 'lifedx_eticket_script';

      document.body.appendChild(script);
    } else {
      existing_script.parentNode.removeChild(existing_script);
    }

    if (!existing_style) {
      const link = document.createElement('link');

      link.href = `${LIFEDX_EMBED_BASE_URL}/eticket/css/reset.css`;
      link.rel = 'stylesheet';
      link.id = 'lifedx_eticket_style';

      document.body.appendChild(link);
    } else {
      existing_style.parentNode.removeChild(existing_style);
    }
  };

  _getFormFieldsFromLifeDX = lifedx_form_data => {
    const form_fields = {};
    const existing_form_fields = JSON.parse(this.state.eticket.form_fields || '[]');

    // persist any fields that have already been saved
    existing_form_fields.forEach(f => {
      form_fields[f.label] = f.value;
    });

    // update any fields that are coming from lifedx
    lifedx_form_data.forEach(f => {
      form_fields[f.name] = f.value;
    });

    return form_fields;
  };

  _updateEticket = payload => {
    AlqApi.updateEticket(this.props.eticketGuid, payload)
      .then(({ data }) => {
        this.setState({
          eticket: data
        });
      })
      .catch(() => {
        // fail silently, it's ok if the data doesn't save
      });
  };

  render() {
    const { onClose, title } = this.props;
    const { loading } = this.state;

    return (
      <>
        <Modal loading={loading} maxWidth='auto' onClose={onClose} title={title}>
          <div ref={this.contentRef}>
            <div id='lifedx_eticket_form' ref={this._handleLifedxEticketDivLoaded} />
          </div>
        </Modal>
      </>
    );
  }
}

LifedxEticketModal.contextType = ProductContext;

export default LifedxEticketModal;
